import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import {Link} from "gatsby";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        transition: "0.3s",
        borderRadius: "50%",
        cursor: "pointer",
        padding: "6px 6px",
        width: "24px",
        height: "24px",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
    },
    iconButtonPrimary: {
        color: theme.palette.primary.main,
        "&:hover": {
            background: "rgba(78, 62, 253,0.13)"
        }
    },
    iconButtonSecondary: {
        color: "rgb(0,0,0,0.7)",
        "&:hover": {
            background: "rgba(235, 235, 235,1)"
        }
    }
}));

interface BaseButtonProps {
    variant?: "primary" | "secondary",
    onClick?: () => void,
    icon?: any,
    to?: string,
    component?: "span" | "button",
    children?: React.ReactNode,
    style?: React.CSSProperties,
    key?: string
}

function BaseIconButton (
    {
        onClick,
        children,
        variant="primary",
        style,
        key,
    } : BaseButtonProps) {

    const classes = useStyles();

    return <div
        key={key}
        style={style}
        className={classNames(
            {[classes.iconButton] : true},
            {[classes.iconButtonPrimary] : variant==="primary"},
            {[classes.iconButtonSecondary] : variant==="secondary"}
        )}
        onClick={onClick ?? null}
    >
        {children}
    </div>
}

function CustomIconButton (props: BaseButtonProps) {

    const base = <BaseIconButton {...props}/>;

    return ( props.to ?
            (props.to.includes("http") ?
                    <a href={props.to} rel="noopener noreferrer" target={"_blank"}>
                        {base}
                    </a>
                    :
                    <Link to={props.to}>
                        {base}
                    </Link>
            )
            :
            base
    )
}

export default CustomIconButton;