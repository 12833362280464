import React, { useState } from "react";
import InputBase from "@material-ui/core/es/InputBase/index";
import InputAdornment from "@material-ui/core/InputAdornment/index";
import { IconButton, InputBaseProps, Theme } from "@material-ui/core";
import classNames from "classnames";
import Collapse from "@material-ui/core/Collapse";
import Text from "../data-display/text";
import Icon from "react-eva-icons";
import useTheme from "@material-ui/core/styles/useTheme";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MaskedInput from "react-text-mask";

const useStyles = makeStyles((theme: Theme) => ({
  padding: {
    padding: "12px 16px"
  },
  input: {
    width: "100%",
    color: "black",
    background: "white",
    border: "2px solid rgb(232,232,232,0.9)",
    borderRadius: "8px",
    lineHeight: "1.5",
    boxSizing: "border-box",
    fontFamily: "'HKGroteskMedium', sans-serif !important",
    flexGrow: 1,
    margin: "8px 0px",
    boxShadow: "0px 0px 12px 0px rgba(70,68,137,0.0)",
    fontSize: "16px",
    height: "48px !important",
    transition: theme.transitions.create(["box-shadow", "border", "background"], {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s"
    })
  },
  hover: {
    transition: theme.transitions.create(["border", "box-shadow", "background"], {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s"
    }),
    boxShadow: "0px 4px 24px -8.5px rgba(70,68,137,0.08)"
  },
  focus: {
    transition: theme.transitions.create(["border", "box-shadow", "background"], {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.3s"
    }),
    border: "2px solid rgb(232,232,232,0.8)",
    boxShadow: "0px 7px 26px -5.5px rgba(70,68,137,0.11)"
  },
  fullHeight: {
    height: "100% !important"
  },
  textField: {
    height: "unset !important",
    padding: "16px 16px"
  },
  borderLess: {
    border: "0",
    margin: 0,
    borderRadius: "0px",
    background: "transparent"
  },
  bigFont: {
    fontSize: "20px",
    height: "50px !important",
    fontFamily: "'HKGroteskBold', sans-serif !important"
  },
  iconSize: {
    width: "18px",
    height: "18px",
    [theme.breakpoints.down("md")]: {
      width: "14px",
      height: "14px"
    }
  },
  errorBorder: {
    border: "2px solid rgba(235, 80, 60, 0.5)",
    boxShadow: "none"
  },
  errorColor: {
    color: "rgb(198,53,89)"
  },
  disabled: {
    color: "rgb(80,80,80)"
  }
}));

interface BaseInputProps extends Omit<Pick<InputBaseProps, Exclude<keyof InputBaseProps, "classes">>, "error" | "ref" | "defaultValue"> {
  error?: string | undefined;
  key?: string;
  defaultValue?: string | number | ReadonlyArray<string>;
  label?: string;
  value?: string;
  bare?: boolean;
  bigFont?: boolean;
  rootStyle?: React.CSSProperties;
  errorStyle?: React.CSSProperties;
  max?: string;
  min?: string;
  maxLength?: string;
  minLength?: string;
  forceDisabled?: boolean;
  mask?: string;
}

function BaseInput({ onKeyDown, inputRef, defaultValue, autoComplete, value, key, type, placeholder, onChange, style, name, multiline, rowsMax, rowsMin, autoFocus, rows, disabled, forceDisabled, error, bare, required, rootStyle, errorStyle, label, bigFont, max, min, startAdornment, endAdornment, autoCapitalize, maxLength, minLength }: BaseInputProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [view, setView] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  const handleSwitchView = (e) => {
    e.preventDefault();
    setView(!view);
  };

  const handleFocus = (e) => {
    if (e) e.preventDefault();
    setFocus(!focus);
  };

  return (
    <div style={{ position: "relative", ...rootStyle }}>
      {label ? (
        <Text variant={"overline"} component={"p"} style={{ color: "grey", lineHeight: "2", marginLeft: "6px", marginTop: "8px" }}>
          {label.toString().toUpperCase()}
        </Text>
      ) : (
        ""
      )}
      <InputBase
        inputRef={inputRef}
        autoCapitalize={autoCapitalize}
        key={key}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        rows={rows}
        autoFocus={autoFocus}
        rowsMax={rowsMax}
        rowsMin={rowsMin}
        onFocus={handleFocus}
        onBlur={handleFocus}
        onKeyDown={onKeyDown}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        multiline={multiline}
        classes={{
          input: classes.padding,
          disabled: classes["disabled"]
        }}
        className={classNames({ [classes.input]: true }, { [classes.textField]: multiline }, { [classes.focus]: !bare && focus && !bare }, { [classes.hover]: !bare && hover && !focus }, { [classes.bigFont]: bigFont }, { [classes.borderLess]: bare }, { [classes.errorBorder]: error && error !== "" && !bare }, { [classes.errorColor]: error && error !== "" })}
        inputProps={{
          max: max,
          min: min,
          maxLength: maxLength,
          minLength: minLength
        }}
        name={name}
        value={value}
        type={!view ? type : "text"}
        placeholder={placeholder}
        onChange={onChange}
        style={{
          marginTop: !bare ? (label ? "0px" : "8px") : 0,
          padding: multiline ? "0px" : "inherit",
          ...style
        }}
        required={required}
        disabled={disabled || forceDisabled}
        startAdornment={startAdornment}
        endAdornment={(() => {
          switch (type) {
            case "password":
              return (
                <InputAdornment position="end">
                  <IconButton onClick={handleSwitchView} style={{ padding: "8px", marginRight: "12px", height: "32px", width: "32px" }} onFocus={handleFocus} onBlur={handleFocus} tabIndex="-1" disableRipple disableTouchRipple>
                    <div style={{ width: "18px", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                      <Fade in={view}>
                        <div style={{ position: "absolute", transform: "translateY(3px)" }}>
                          <Icon name={"eye"} size="large" fill={theme.palette.primary.main} />
                        </div>
                      </Fade>
                      <Fade in={!view}>
                        <div style={{ position: "absolute", transform: "translateY(3px)" }}>
                          <Icon name={"eye-off"} size="large" fill={"grey"} />
                        </div>
                      </Fade>
                    </div>
                  </IconButton>
                </InputAdornment>
              );
            default:
              if (endAdornment) return endAdornment;
              else return null;
          }
        })()}
      />
      <Collapse in={error !== undefined && error !== "" && error !== "empty"} style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "8px", ...errorStyle }}>
          <Icon name={"alert-triangle"} size={"small"} fill={"rgb(234,84,105)"} />
          <Text variant={"caption"} style={{ color: "rgb(198,53,89)", marginLeft: "12px", marginBottom: "2px", minHeight: "12px", textTransform: "none" }}>
            {error}
          </Text>
        </div>
      </Collapse>
    </div>
  );
}

const FormInput = (props: BaseInputProps) => {

  return (() => {
    switch (props.type) {
      case "tel":
        return <MaskedInput
            {...props}
            mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"\u2000"}
            guide={false}
            render={(ref, inputProps) => {
              return <BaseInput inputRef={ref} {...inputProps} />
            }}
        />;
      default:
        return <BaseInput {...props} />;
    }
  })();
};

export type { BaseInputProps };
export default FormInput;
