import React from "react";
import {FaStar, FaRegStar} from "react-icons/fa";
import Text from "../data-display/text";

const StarRating = ({totalAverage, totalCount, size= 12} : { totalAverage: number, totalCount?: number, size?: number }) => {

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            { (totalAverage===null || totalAverage===0) &&
                <Text variant={"caption"} component={"span"} style={{ color: "grey" }}>{`No Reviews`}</Text>
            }
            { (totalAverage!==null && totalAverage>0) &&
                [1,2,3,4,5].map(i => {
                    return i <= totalAverage ?
                        <FaStar key={i.toString() + "star"}
                                color={i <= totalAverage ? "rgb(255, 186, 0)" : "rgb(150,150,150)"}
                                style={{ width: `${size}px`, height: `${size}px`, marginRight: "2px" }}/>
                        :
                        <FaRegStar key={i.toString() + "star"}
                                   color={i <= totalAverage ? "rgb(255, 186, 0)" : "rgb(150,150,150)"}
                                   style={{ width: `${size}px`, height: `${size}px`, marginRight: "2px" }}/>
                })
            }
            { (totalCount!==null && totalCount > 0) &&
                <span style={{ marginLeft: "6px", lineHeight: "0", fontFamily: "'HKGroteskBold', sans-serif", fontSize: `${size}px`, color: "grey", letterSpacing: "1px" }}>{`(${totalCount.toString()})`}</span>
            }
        </div>
    )
}

export default StarRating;