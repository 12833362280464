import React from 'react';
import FormErrorText from '../../data-display/form-error-text';
import Form from '../../forms/form-wrapper';
import FormInput from '../../forms/form-input';
import {privateUsers} from '../../../utils/firebase.utils';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/index.reducers';
import {Dispatch} from 'redux';
import {UserActionTypes} from '../../../redux/types/user-type';
import {BirthDate} from '../../../models/user-data';
import {updateUser} from '../../../redux/actions/user-actions';
import moment from 'moment';
import DialogBottomButton from "../../dialogs/dialog-bottom-button";

const BirthDateForm = (props) => {

    const {userData} = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch<Dispatch<UserActionTypes>>();
    const next = props.next


    const saveBirthDate = (values: Record<string, any>) => {
        next&& props.onContinue()
        return new Promise((resolve, reject) => {
            let today = new Date();
            let birthDate: Date = new Date(values.birthDate.toString());
            if (!(today.getFullYear() - birthDate.getFullYear() > 18 || (today.getFullYear() - birthDate.getFullYear() === 18 && today.getMonth() >= birthDate.getMonth() && today.getDate() >= birthDate.getDate()) )) {
                reject({birthDate: "You have to be over 18 to work as a Tasker."})
            } else {
                let date = new BirthDate({
                    day: birthDate.getDate(),
                    month: birthDate.getMonth() + 1,
                    year: birthDate.getFullYear(),
                });
                privateUsers
                    .doc(userData.uid)
                    .set({birthDate: date.toJson()}, {merge: true})
                    .then(() => {
                        userData.birthDate = date;
                        dispatch(updateUser(userData));
                        resolve("Success");
                        props.onClose();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    return (
        <Form
            onSubmit={saveBirthDate}
            initialValues={{ birthDate: userData.birthDate?.inputDate }}
            style={{ display: 'flex', height: "100%" }}
        >
            <FormInput
                max={moment(new Date()).format('YYYY-MM-DD')}
                label={'Birth Date'}
                placeholder={'Birth Date'}
                name={'birthDate'}
                type={'date'}
                required
            />
            <FormErrorText/>
            <DialogBottomButton showBackgroundFade={true}>
                Save
            </DialogBottomButton>
        </Form>
    );
};

export default BirthDateForm;