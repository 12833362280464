import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core";
import { Link } from "gatsby";
import InlineLink from "../data-display/inline-link";
import Text from "../data-display/text";
import Sydetasker from "../../images/sydework.png";
import Avatar from "../avatar/avatar";
import MenuWrapper from "../menu-popper/menu-wrapper";
import MenuListItem from "../menu-popper/menu-list-item";
import {
  BsFillCaretDownFill,
  FiChevronRight,
  HiMenuAlt4,
} from "react-icons/all";
import Icon from "react-eva-icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import categories from "../../content/services.json";
import Grid from "@material-ui/core/Grid";
import { UserData } from "../../models/user-data";
import NotificationsList from "../notifications-list/notifications-list";
import { auth } from "../../utils/firebase.utils";
import firebase from "firebase";
import { Hidden } from "@material-ui/core";
import { HiBell, RiMessengerFill } from "react-icons/all";
import PostTaskWrapper from "../post-task/post-task-wrapper";
import { useLocation } from "@reach/router";
// import categories from "../../main-pages/services/categories.json";
import NumberFormat from "react-number-format";

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backdropFilter: "blur(10px)",
    background: "rgba(255, 255, 255, 0.92)",
    transition: "0.3s",
    height: "64px",
    boxShadow: "none",

    zIndex: 1200,
    boxSizing: "border-box",
  },
  toolBar: {
    minHeight: "64px",
    justifyContent: "center",
  },
  myAccountChip: {
    display: "flex",
    alignItems: "center",
    borderRadius: "40px",
    border: "1px solid rgb(240,240,240)",
    padding: "6px 8px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgb(247, 248, 251)",
    "&:hover": {
      background: "rgb(240,240,240)",
    },
  },
  loginButtons: {
    display: "flex",
    alignItems: "center",
    borderRadius: "40px",
    border: "1px solid rgb(240,240,240)",
    padding: "6px 8px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(247, 248, 251,0)",
    "&:hover": {
      background: "rgba(247, 248, 251,1)",
    },
  },
  loginButtonsPrimaryLight: {
    display: "flex",
    alignItems: "center",
    borderRadius: "40px",
    border: "1px solid rgb(240,240,240)",
    padding: "6px 8px",
    cursor: "pointer",
    transition: "0.3s",
    background: "rgba(108, 82, 255,0.06)",
    "&:hover": {
      background: "rgba(78, 62, 253,0.13)",
    },
  },
  loginButtonsPrimaryDark: {
    display: "flex",
    alignItems: "center",
    borderRadius: "40px",
    border: "1.5px solid rgba(255, 255, 255,0.6)",
    padding: "6px 8px",
    cursor: "pointer",
    transition: "0.3s",
    backdropFilter: "blur(18px)",
    background: "rgba(255, 255, 255,0.06)",
    "&:hover": {
      background: "rgba(255, 255, 255,0.13)",
    },
  },
  iconButton: {
    boxSizing: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "40px",
    border: "1px solid rgb(240,240,240)",
    padding: "6px 8px",
    cursor: "pointer",
    width: "24px",
    height: "24px",
    transition: "0.3s",
    background: "rgb(247, 248, 251)",
    "&:hover": {
      background: "rgb(240,240,240)",
    },
  },
  iconNum: {
    boxSizing: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "40px",
    border: "1px solid rgb(240,240,240)",
    padding: "6px 8px",
    cursor: "pointer",
    width: "24px",
    height: "24px",
    transition: "0.3s",
    // background: "rgb(247, 248, 251)",
  },
  dot: {
    height: "25px",
    width: "25px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [isAuthenticated, setAuth] = useState(false);
  const [userData, setUserData] = useState<UserData>(null);
  const [dark, setDark] = useState<boolean>(false);
  const getVerticalRule = (
    <span
      style={{
        borderLeft: "2px solid rgb(175,175,175, 0.15)",
        height: "24px",
        margin: "0 20px",
      }}
    />
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let doc = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get();
        if (doc.exists) {
          let data = doc.data();
          data.uid = user.uid;

          let privateDoc = await firebase
            .firestore()
            .collection("users_stripe")
            .doc(user.uid)
            .get();
          if (privateDoc.exists) {
            data.phoneNumber = privateDoc.data()["phoneNumber"];
            data.birthDate = privateDoc.data()["birthDate"];
          }

          setUserData(new UserData(data));
          setAuth(true);
        }
      } else {
        setAuth(false);
        setUserData(null);
      }
    });
  }, [userData]);

  const [scrollPast, setScrollPast] = useState(false);

  useEffect(() => {
    let color =
      ((location.pathname.includes("/services/") &&
        location.pathname !== "/services/") ||
        location.pathname === "/support" ||
        location.pathname === "/support/") &&
      !scrollPast;
    setDark(color);
    window.addEventListener("scroll", () => {
      let v = 0.8;
      if (location.pathname === "/support" || location.pathname === "/support/")
        v = 0.5;
      if (window.scrollY > window.visualViewport.height * v - 64) {
        setScrollPast(true);
      } else {
        setScrollPast(false);
      }
    });
  }, [location.pathname]);

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{
          borderBottom: `2px solid rgba(175,175,175,0.15)`,
          background: dark ? "rgba(0,0,0,0.7)" : undefined,
          backdropFilter: dark ? " blur(42px) saturate(1.2)" : undefined,
        }}
      >
        <Container fixed={!smDown} maxWidth={"lg"}>
          <Toolbar className={classes.toolBar} disableGutters={true}>
            <InlineLink variant={"primary"} to={"/"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto",
                }}
              >
                <img
                  loading={"lazy"}
                  src={Sydetasker}
                  height="28px"
                  alt="Sydetasker Official Logo"
                  style={{ filter: dark ? "grayscale(1) brightness(3)" : "" }}
                />
                {!smDown && (
                  <Text
                    variant={"h6"}
                    style={{
                      marginLeft: "10px",
                      transform: "translateY(-1px)",
                      color: dark ? "white" : "#66D1BA",
                      fontWeight: "bold",
                    }}
                    sydeLogo
                  >
                    Syde
                    <span
                      style={{
                        color: !dark ? theme.palette.primary.main : "white",
                      }}
                    >
                      tasker
                    </span>
                  </Text>
                )}
              </div>
            </InlineLink>
            <Hidden xsDown>
              {getVerticalRule}
              {userData?.requestDeactivate == false ? (
                <div>
                  {isAuthenticated ? (
                    <PostTaskWrapper>
                      <div
                        className={
                          dark
                            ? classes.loginButtonsPrimaryDark
                            : classes.loginButtonsPrimaryLight
                        }
                        style={{
                          backgroundColor: !dark
                            ? theme.palette.primary.main
                            : "",
                        }}
                      >
                        <Text
                          variant={"body2"}
                          bold
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{
                              margin: "0 8px",
                              color: "rgba(255, 255, 255, 0.92)",
                            }}
                          >
                            Post Task
                          </span>
                        </Text>
                      </div>
                    </PostTaskWrapper>
                  ) : (
                    <InlineLink variant={"primary"} to={"/login"}>
                      <div
                        className={
                          dark
                            ? classes.loginButtonsPrimaryDark
                            : classes.loginButtonsPrimaryLight
                        }
                        style={{
                          backgroundColor: !dark
                            ? theme.palette.primary.main
                            : "",
                        }}
                      >
                        <Text
                          variant={"body2"}
                          bold
                          style={{ margin: "0 8px", color: "white" }}
                        >
                          Post Task
                        </Text>
                      </div>
                    </InlineLink>
                  )}
                </div>
              ) : (
                <div
                  className={
                    dark
                      ? classes.loginButtonsPrimaryDark
                      : classes.loginButtonsPrimaryLight
                  }
                  style={{
                    backgroundColor: !dark ? theme.palette.primary.main : "",
                  }}
                >
                  <InlineLink variant={"primary"} to={"/login"}>
                    <Text
                      variant={"body2"}
                      bold
                      style={{ margin: "0 8px", color: "white" }}
                    >
                      Post Task
                    </Text>
                  </InlineLink>
                </div>
              )}

              <div style={{ width: "16px" }} />
              <Text variant={"body2"} bold>
                <InlineLink
                  variant={"secondary"}
                  to={"/tasks"}
                  style={{ color: dark ? "white" : "black" }}
                >
                  Browse Tasks
                </InlineLink>
              </Text>
              <div style={{ width: "16px" }} />
              <div style={{ display: "flex" }}>
                <MenuWrapper
                  closeOnClick
                  placement={"bottom"}
                  items={
                    <Grid container style={{ maxWidth: "350px" }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InlineLink to={"/services"}>
                          <MenuListItem large>
                            <div>
                              <Text
                                variant={"body2"}
                                bold
                                style={{ color: dark ? "white" : "black" }}
                              >
                                Services
                              </Text>
                              <Text
                                variant={"caption"}
                                regular
                                style={{ opacity: "0.5", maxWidth: "80%" }}
                              >
                                Here are some of our most popular task
                                categories...
                              </Text>
                            </div>
                          </MenuListItem>
                        </InlineLink>
                        <div style={{ height: "8px" }} />
                      </Grid>

                      {categories.map((c) => {
                        return (
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            key={c.navTitle}
                          >
                            <Text
                              variant={"body2"}
                              regular
                              style={{ padding: "5px 12px" }}
                            >
                              <InlineLink
                                to={"/services/" + c.location}
                                variant={"primary"}
                                style={{ color: "black" }}
                              >
                                {c.navTitle}{" "}
                                <FiChevronRight
                                  style={{ opacity: "0.5", marginLeft: "2px" }}
                                  size={14}
                                />
                              </InlineLink>
                            </Text>
                          </Grid>
                        );
                      })}
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ height: "16px" }} />
                      </Grid>
                    </Grid>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "64px",
                    }}
                  >
                    <Text variant={"body2"} bold>
                      <InlineLink
                        variant={"secondary"}
                        style={{ color: dark ? "white" : "black" }}
                      >
                        Services
                        <BsFillCaretDownFill
                          size={12}
                          style={{
                            marginLeft: "4px",
                            transform: "translateY(2px)",
                          }}
                          color={dark ? "white" : "black"}
                        />
                      </InlineLink>
                    </Text>
                  </div>
                </MenuWrapper>
              </div>
              <div style={{ width: "16px" }} />
              {isAuthenticated && (
                <Text variant={"body2"} bold>
                  <InlineLink
                    variant={"secondary"}
                    to={"/my-tasks"}
                    style={{ color: dark ? "white" : "black" }}
                  >
                    My Tasks
                  </InlineLink>
                </Text>
              )}
            </Hidden>
            <div className={classes.grow} />
            <Hidden xsDown>
              <Text variant={"body2"} bold>
                <InlineLink
                  variant={"secondary"}
                  to={"/support"}
                  style={{ color: dark ? "white" : "black" }}
                >
                  Need Help?
                </InlineLink>
              </Text>
              <div style={{ width: "20px" }} />
              {isAuthenticated ? (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      height: "64px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link to={`/profile${userData?.slug_title}`}>
                      <div className={classes.myAccountChip}>
                        <Avatar
                          userId={userData?.slug_title}
                          name={userData.name}
                          profilePicture={userData.profilePicture}
                          size={22}
                        />
                        {!smDown && (
                          <Text
                            variant={"body2"}
                            bold
                            style={{ marginLeft: "6px", marginRight: "8px" }}
                          >
                            {userData.firstName}
                          </Text>
                        )}
                      </div>
                    </Link>
                  </div>

                  <div style={{ width: "12px" }} />
                  <MenuWrapper
                    items={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "10px 0px",
                          minWidth: "380px",
                          maxWidth: "380px",
                          minHeight: "240px",
                          maxHeight: "600px",
                          overflow: "overlay",
                          WebkitOverflowScrolling: "touch",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            padding: "0 12px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Text
                            variant={"body1"}
                            black
                            style={{ marginBottom: "18px" }}
                          >
                            Alerts
                          </Text>
                        </div>
                        <NotificationsList />
                      </div>
                    }
                    closeOnClick
                  >
                    <div
                      style={{
                        height: "64px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {userData?.unreadNotifications > 0 ? (
                        <div className={classes.iconButton}>
                          <div
                            className={classes.iconNum}
                            style={{
                              height: "10px",
                              width: "10px",
                              fontSize: "11px",
                              color: "white",
                              marginBottom: "30px",
                              fontFamily: "HKGroteskMedium",
                              fontWeight: "bold",
                              background: theme.palette.primary.main,
                            }}
                          >
                            {userData?.unreadNotifications > 0 &&
                            userData?.unreadNotifications < 9
                              ? userData?.unreadNotifications
                              : "+9"}
                          </div>
                          <div>
                            <HiBell
                              size={20}
                              style={{
                                color: "rgb(40,40,40)",
                                marginRight: "20px",
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "64px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          <div className={classes.iconButton}>
                            <HiBell
                              size={20}
                              style={{ color: "rgb(40,40,40)" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </MenuWrapper>
                  <div style={{ width: "12px" }} />
                  {userData?.unreadMessages > 0 ? (
                    <div
                      style={{
                        height: "64px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InlineLink to={"/messaging"}>
                        <div className={classes.iconButton}>
                          {userData?.unreadMessages > 0 && (
                            <div
                              className={classes.iconNum}
                              style={{
                                height: "10px",
                                width: "10px",
                                fontSize: "11px",
                                color: "white",
                                marginBottom: "30px",
                                fontFamily: "HKGroteskMedium",
                                fontWeight: "bold",
                                background: theme.palette.primary.main,
                              }}
                            >
                              {userData?.unreadMessages > 0 &&
                              userData?.unreadMessages < 9
                                ? userData?.unreadMessages
                                : "+9"}
                            </div>
                          )}
                          <div>
                            <RiMessengerFill
                              size={18}
                              style={{
                                color: "rgb(40,40,40)",
                                marginRight: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </InlineLink>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "64px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <InlineLink to={"/messaging"}>
                        <div className={classes.iconButton}>
                          <RiMessengerFill
                            size={20}
                            style={{ color: "rgb(40,40,40)" }}
                          />
                        </div>
                      </InlineLink>
                    </div>
                  )}

                  <div style={{ width: "12px" }} />
                  {isAuthenticated && (
                    <MenuWrapper
                      disablePadding
                      items={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "14px 8px",
                            minWidth: "350px",
                            background: `linear-gradient(${theme.palette.background.default}, white)`,
                          }}
                        >
                          <Link
                            to={`/profile${userData?.slug_title}`}
                            style={{ width: "100%" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                                marginBottom: "12px",
                              }}
                            >
                              <Avatar
                                userId={userData?.slug_title}
                                name={userData.name}
                                profilePicture={userData.profilePicture}
                                size={48}
                              />
                              <Text
                                variant={"body1"}
                                black
                                style={{ marginTop: "6px" }}
                              >
                                {userData.name}
                              </Text>
                              <Text
                                variant={"caption"}
                                medium
                                style={{ color: "grey" }}
                              >
                                {userData.email}
                              </Text>
                            </div>
                          </Link>
                          <Link to={`/account`} style={{ width: "100%" }}>
                            <MenuListItem
                              large
                              icon={
                                <Icon
                                  fill={"rgb(101, 119, 134)"}
                                  name={"settings-2-outline"}
                                  size={"medium"}
                                />
                              }
                            >
                              Account Settings
                            </MenuListItem>
                          </Link>
                          <MenuListItem
                            large
                            onClick={() => {
                              auth.signOut().then();
                            }}
                            icon={
                              <Icon
                                fill={theme.palette.error.main}
                                name={"power"}
                                size={"medium"}
                              />
                            }
                            error
                          >
                            <div style={{ color: "rgb(198, 53, 89)" }}>
                              Logout
                            </div>
                          </MenuListItem>
                        </div>
                      }
                    >
                      <div
                        style={{
                          height: "64px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.iconButton}>
                          <BsFillCaretDownFill style={{ color: "black" }} />
                        </div>
                      </div>
                    </MenuWrapper>
                  )}
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Text variant={"body2"} bold>
                    <InlineLink
                      variant={"secondary"}
                      to={"/login"}
                      style={{ color: dark ? "white" : "black" }}
                    >
                      {" "}
                      Sign In
                    </InlineLink>
                  </Text>
                  <div style={{ width: "20px" }} />
                  <InlineLink
                    variant={"primary"}
                    to={"/register"}
                    style={{ color: theme.palette.primary.main }}
                  >
                    <div className={classes.loginButtonsPrimaryLight}>
                      <Text
                        selectable={false}
                        variant={"body2"}
                        bold
                        style={{
                          margin: "0 8px",
                          color: theme.palette.primary.main,
                        }}
                      >
                        Sign Up
                      </Text>
                    </div>
                  </InlineLink>
                </div>
              )}
            </Hidden>
            <Hidden smUp>
              {isAuthenticated ? (
                <PostTaskWrapper>
                  <div className={classes.loginButtonsPrimaryLight}>
                    <Text
                      selectable={false}
                      variant={"body2"}
                      bold
                      style={{
                        margin: "0 8px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Post a Task
                    </Text>
                  </div>
                </PostTaskWrapper>
              ) : (
                <InlineLink
                  variant={"primary"}
                  to={"/login"}
                  style={{ color: theme.palette.primary.main }}
                >
                  <div className={classes.loginButtonsPrimaryLight}>
                    <Text
                      selectable={false}
                      variant={"body2"}
                      bold
                      style={{
                        margin: "0 8px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      Post a Task
                    </Text>
                  </div>
                </InlineLink>
              )}

              <div style={{ width: "20px" }} />

              {!isAuthenticated && (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Text variant={"body2"} bold>
                      <InlineLink
                        variant={"secondary"}
                        to={"/login"}
                        style={{ color: dark ? "white" : "black" }}
                      >
                        Sign In
                      </InlineLink>
                    </Text>
                    <div style={{ width: "20px" }} />
                    <InlineLink
                      variant={"primary"}
                      to={"/register"}
                      style={{ color: theme.palette.primary.main }}
                    >
                      <div
                        className={
                          dark
                            ? classes.loginButtonsPrimaryDark
                            : classes.loginButtonsPrimaryLight
                        }
                      >
                        <Text
                          selectable={false}
                          variant={"body2"}
                          bold
                          style={{
                            margin: "0 8px",
                            color: theme.palette.primary.main,
                          }}
                        >
                          Sign Up
                        </Text>
                      </div>
                    </InlineLink>
                  </div>
                </>
              )}
              {isAuthenticated && (
                <>
                  <MenuWrapper
                    items={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "10px 0px",
                          minWidth: "380px",
                          maxWidth: "380px",
                          minHeight: "240px",
                          maxHeight: "600px",
                          overflow: "overlay",
                          WebkitOverflowScrolling: "touch",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            padding: "0 12px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Text
                            variant={"body1"}
                            black
                            style={{ marginBottom: "18px" }}
                          >
                            Alerts
                          </Text>
                        </div>
                        <NotificationsList />
                      </div>
                    }
                    closeOnClick
                  >
                    <div
                      style={{
                        height: "64px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className={classes.iconButton}>
                        <HiBell size={20} style={{ color: "rgb(40,40,40)" }} />
                      </div>
                    </div>
                  </MenuWrapper>
                  <div
                    style={{
                      height: "64px",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <InlineLink to={"/messaging"}>
                      <div className={classes.iconButton}>
                        <RiMessengerFill
                          size={20}
                          style={{ color: "rgb(40,40,40)" }}
                        />
                      </div>
                    </InlineLink>
                  </div>
                </>
              )}
              <div style={{ width: "20px" }} />
              <MenuWrapper
                disablePadding
                items={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "14px 8px",
                      width: "calc(100vw - 32px)",
                      background: `linear-gradient(${theme.palette.background.default}, white)`,
                    }}
                  >
                    {isAuthenticated && (
                      <>
                        <Link
                          to={`/profile${userData?.slug_title}`}
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: "12px",
                            }}
                          >
                            <Avatar
                              userId={userData?.slug_title}
                              name={userData?.name}
                              profilePicture={userData?.profilePicture}
                              size={48}
                            />
                            <Text
                              variant={"body1"}
                              black
                              style={{ marginTop: "6px" }}
                            >
                              {userData?.name}
                            </Text>
                            <Text
                              variant={"caption"}
                              medium
                              style={{ color: "grey" }}
                            >
                              {userData?.email}
                            </Text>
                          </div>
                        </Link>
                        <Link to={"/account"} style={{ width: "100%" }}>
                          <MenuListItem
                            large
                            icon={
                              <Icon
                                fill={"rgb(101, 119, 134)"}
                                name={"settings-2-outline"}
                                size={"medium"}
                              />
                            }
                          >
                            Account Settings
                          </MenuListItem>
                        </Link>
                      </>
                    )}
                    <Link to={"/tasks"} style={{ width: "100%" }}>
                      <MenuListItem
                        large
                        icon={
                          <Icon
                            fill={"rgb(101, 119, 134)"}
                            name={"search-outline"}
                            size={"medium"}
                          />
                        }
                      >
                        Browse Tasks
                      </MenuListItem>
                    </Link>

                    {isAuthenticated && (
                      <>
                        <Link to={"/my-tasks/poster"} style={{ width: "100%" }}>
                          <MenuListItem
                            large
                            icon={
                              <Icon
                                fill={"rgb(101, 119, 134)"}
                                name={"file-text-outline"}
                                size={"medium"}
                              />
                            }
                          >
                            My Tasks
                          </MenuListItem>
                        </Link>
                        <MenuListItem
                          large
                          onClick={() => {
                            auth.signOut().then();
                          }}
                          icon={
                            <Icon
                              fill={theme.palette.error.main}
                              name={"power"}
                              size={"medium"}
                            />
                          }
                          error
                        >
                          Logout
                        </MenuListItem>
                      </>
                    )}
                  </div>
                }
              >
                <div
                  style={{
                    height: "64px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.iconButton}>
                    <HiMenuAlt4 style={{ color: "black" }} />
                  </div>
                </div>
              </MenuWrapper>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default NavBar;
