import moment from "moment";
import { any } from "prop-types";
import Address from "./address";
export class BirthDate {
    day: number | null = null;
    month: number | null = null;
    year: number | null = null;

    get date() {
        if (this.day !== null && this.month !== null && this.year !== null) {
            return new Date(this.year, (this.month - 1), this.day);
        }
        else
            return null;
    }

    get formattedDate() {
        if (this.day !== null && this.month !== null && this.year !== null)
            return moment(this.date).format('MMM DD, YYYY')
        else
            return "";
    }

    get inputDate() {
        if (this.day !== null && this.month !== null && this.year !== null)
            return moment(this.date).format('YYYY-MM-DD')
        else
            return "";
    }

    constructor(data?: { [field: string]: any }) {
        this.day = data?.day ?? null;
        this.month = data?.month ?? null;
        this.year = data?.year ?? null;
        this.year = data?.year ?? null;
    }

    toJson(): BirthDate {
        return Object.assign({}, this);
    }
}

export class Badge {
    name: string | null = null;
    status: string | null = null;
    verificationId: string | null = null;

    constructor(data?: { [field: string]: any }) {
        this.name = data?.name ?? null;
        this.status = data?.status ?? null;
        this.verificationId = data?.verificationId ?? null;
    }

    toJson(): Badge {
        return Object.assign({}, this);
    }
}

export class ReviewIndex {
    totalAverage: number = 0.0;
    totalCount: number = 0;
    oneCount: number = 0;
    twoCount: number = 0;
    threeCount: number = 0;
    fourCount: number = 0;
    fiveCount: number = 0;

    constructor(data?: { [field: string]: any }) {
        this.totalAverage = data?.totalAverage ?? 0.0;
        this.totalCount = data?.totalCount ?? 0;
        this.oneCount = data?.oneCount ?? 0;
        this.twoCount = data?.twoCount ?? 0;
        this.threeCount = data?.threeCount ?? 0;
        this.fourCount = data?.fourCount ?? 0;
        this.fiveCount = data?.fiveCount ?? 0;
    }

    toJson(): ReviewIndex {
        return Object.assign({}, this);
    }
}

export class CompletedRate {
    complete_task: number = 0.0;
    complete_rate: number = 0;
    cancel_task: number = 0;
    cancel_rate: number = 0;
  

    constructor(data?: { [field: string]: any }) {
        this.complete_task = data?.complete_task ?? 0.0;
        this.complete_rate = data?.complete_rate ?? 0;
        this.cancel_task = data?.cancel_task ?? 0;
        this.cancel_rate = data?.cancel_rate ?? 0;

    }

    toJson(): CompletedRate {
        return Object.assign({}, this);
    }
}
export class CompletedRateTasker {
    complete_task: number = 0.0;
    complete_rate: number = 0;
    cancel_task: number = 0;
    cancel_rate: number = 0;
  

    constructor(data?: { [field: string]: any }) {
        this.complete_task = data?.complete_task ?? 0.0;
        this.complete_rate = data?.complete_rate ?? 0;
        this.cancel_task = data?.cancel_task ?? 0;
        this.cancel_rate = data?.cancel_rate ?? 0;

    }

    toJson(): CompletedRate {
        return Object.assign({}, this);
    }
}
export class CompletedRatePoster {
    complete_task: number = 0.0;
    complete_rate: number = 0;
    cancel_task: number = 0;
    cancel_rate: number = 0;
  

    constructor(data?: { [field: string]: any }) {
        this.complete_task = data?.complete_task ?? 0.0;
        this.complete_rate = data?.complete_rate ?? 0;
        this.cancel_task = data?.cancel_task ?? 0;
        this.cancel_rate = data?.cancel_rate ?? 0;

    }

    toJson(): CompletedRate {
        return Object.assign({}, this);
    }
}


export class UserData {
    communicationEmail: string="";
    slug_title: string = "";
    uid: string = "";
    firstName: string = "";
    service_area:string='';
    lastName: string = "";
    profilePicture: string = "";
    email: string = "";
    phoneNumber: string = "";
    acceptTaskerRules: boolean= false;
    birthDate: BirthDate = null;
    requestDeactivate:boolean =null;
    isDeleted:boolean =null;
    state: string = "";
    city: string = "";
    tagLine: string = "";
    deleteUserDate: Date = null;
    requestDeactivateDate: Date = null;
    about: string = "";
    resumeUrl: string ="";
    resumeName: string ="";
    portfolio: string[] = [];
    totalTasks:number;
    education: string[] = [];
    favoriteTaskers:string[]=[];
    skills: string[] = [];
    preferences: string[] = [];
    badges: Badge[] = [];
    workerReviewIndex: ReviewIndex = null;
    taskOwnerReviewIndex: ReviewIndex = null;
    completion_rate:CompletedRate =null;
    completion_rate_tasker:CompletedRateTasker=null;
    completion_rate_poster:CompletedRatePoster=null;
    completionRate: number = 0;
    bankAccountEnabled: boolean = false;
    previouslyHired: string[] = [];
    verifiedStatus: string | null = null;
    unreadNotifications:number;
    unreadMessages:number;

    constructor(data?: { [field: string]: any }) {
        this.uid = data?.uid ?? "";
        this.slug_title = data?.slug_title ?? "";
        this.about = data?.about ?? "";
        this.communicationEmail = data?.communicationEmail ?? "";
        this.firstName = data?.firstName ?? "";
        this.service_area = data?.service_area ?? "";
        this.lastName = data?.lastName ?? "";
        this.profilePicture = data?.profilePicture ?? "";
        this.email = data?.email ?? "";
        this.phoneNumber = data?.phoneNumber ?? "";
        this.birthDate = data?.birthDate != null ? new BirthDate(data.birthDate) : null;
        this.state = data?.state ?? "";
        this.city = data?.city ?? "";
        this.tagLine = data?.tagLine ?? "";
        this.verifiedStatus = data?.verifiedStatus ?? "";
        this.resumeUrl = data?.resumeUrl ?? "";
        this.resumeName = data?.resumeName ?? "";
        this.portfolio = data?.portfolio != null ? data.portfolio : [];
        this.unreadNotifications = data?.unreadNotifications != null ? data.unreadNotifications : 0;
        this.unreadMessages = data?.unreadMessages != null ? data.unreadMessages : 0;
        this.totalTasks = data?.totalTasks != null ? data.totalTasks : null;
        this.education = data?.education != null ? data.education : [];
        this.favoriteTaskers=data?.favoriteTaskers!= null ? data.favoriteTaskers:[];
        this.skills = data?.skills != null ? data.skills : [];
        this.preferences = data?.preferences != null ? data.preferences : [];
        if (data?.badges) {
            this.badges = [];
            data.badges.forEach((b: any) => {
                this.badges.push(new Badge(b));
            });
        } else {
            this.badges = [];
        }
        this.verifiedStatus = data?.verifiedStatus ?? null;
        this.workerReviewIndex = data?.workerReviewIndex != null ? new ReviewIndex(data.workerReviewIndex) : null;
        this.taskOwnerReviewIndex = data?.taskOwnerReviewIndex != null ? new ReviewIndex(data.taskOwnerReviewIndex) : null;
        this.completion_rate= data?.completion_rate != null ? new CompletedRate(data.completion_rate):null;
        this.completion_rate_poster= data?.completion_rate_poster != null ? new CompletedRate(data.completion_rate_poster):null;
        this.completion_rate_tasker= data?.completion_rate_tasker != null ? new CompletedRate(data.completion_rate_tasker):null;
        this.deleteUserDate = data?.deleteUserDate ? (typeof data?.deleteUserDate === "string" ? new Date(data.deleteUserDate) : data.deleteUserDate instanceof Date ? data.deleteUserDate : new Date(data.deleteUserDate.toDate().toString())) : null;
        this.completionRate = data?.completionRate ?? 0.0;
        this.requestDeactivateDate = data?.deleteUserDate ? (typeof data?.requestDeactivateDate === "string" ? new Date(data.requestDeactivateDate) : data.requestDeactivateDate instanceof Date ? data.requestDeactivateDate : new Date(data.requestDeactivateDate.toDate().toString())) : null;
        this.bankAccountEnabled = data?.bankAccountEnabled ?? false;
        this.requestDeactivate =data?.requestDeactivate??false ;
        this.isDeleted =data?.isDeleted??false ;
        this.acceptTaskerRules = data?.acceptTaskerRules ?? false;
        this.previouslyHired = data?.previouslyHired != null ? data.previouslyHired : []
    }

    get name(): string {
        if (this.firstName !== null && this.lastName !== null)
            return this.firstName + " " + this.lastName;
        else
            return "";
    }

    get hasValidDetails(): boolean {
        return (this.email && this.acceptTaskerRules!=false && this.firstName && this.lastName && this.phoneNumber && this.birthDate !== null && this.birthDate.date !== null)
    }

    get isValidTasker(): boolean {
        return (this.hasValidDetails && this.bankAccountEnabled && this.verifiedStatus === "verified")
    }
    
    toPublicData(): UserData {
        let d = Object.assign({}, this);
        delete d.phoneNumber;
        delete d.birthDate;
        return d;
    }
}