import SecondaryButton from "../buttons/secondary-button";
import React, { useRef } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import Text from "../data-display/text";
import Icon from "react-eva-icons";

const DialogBottomButton = ({ data, error, backgroundColor, showBackgroundFade = true, style, children, textColor, to, onClick, disabled = false, icon, isLoading = false, type = 'submit', enableDisableColor = false }: { data?: React.ReactNode, error?: string, backgroundColor?: string; showBackgroundFade?: boolean, children: React.ReactNode, onClick?: () => void, disabled?: boolean, icon?: any, textColor?: string, isLoading?: boolean, style?: React.CSSProperties, enableDisableColor?: boolean, type?: 'submit' | 'button', to?: string }) => {

  const theme = useTheme();
  const bottomArea = useRef<HTMLDivElement>();

  return (
    <>
      <div style={{ height: 8 + (bottomArea?.current?.clientHeight ?? 0) }} />
      <div style={{
        zIndex: 1301,
        borderRadius: "8px",
        background: showBackgroundFade ? "linear-gradient(0deg, rgba(254, 254, 254, 1), rgba(254, 254, 254, 0))" : "",
        position: "absolute", bottom: "0", left: "0", width: "100%",
        display: "flex", alignItems: "center", justifyContent: "center",
        flexDirection: "column",
        padding: "22px 0",
        ...style
      }} ref={bottomArea}>
        {data}
        <div style={{
          background: "white",
          borderRadius: "6px",
          width: "90%",
          boxSizing: "border-box",
          boxShadow: "0 12px 28px 0 rgba(0,0,0,0.1), 0 2px 4px 0 rgba(0,0,0,0.05)",
          padding: "12px 16px",
          display: error ? 'flex' : 'none',
          transform: error ? "translateY(-8px)" : "translateY(12px)",
          transition: "0.3s",
        }}>
          <Icon name={"alert-triangle"} size={"small"} fill={"rgb(234,84,105)"} />
          <Text variant={"caption"} medium component={'p'} style={{
            color: "rgb(198,53,89)",
            marginLeft: "12px",
            marginBottom: "2px",
            minHeight: "12px",
            textTransform: "none"
          }}>
            {error}
          </Text>
        </div>
        <SecondaryButton
          style={{
            filter: disabled ? "grayscale(1)" : "",
            cursor: disabled ? "not-allowed" : ""
          }}
          icon={icon}
          enableDisableColor={enableDisableColor}
          type={type}
          disabled={disabled}
          onClick={onClick}
          isLoading={isLoading}
          background={backgroundColor ? backgroundColor : theme.palette.primary.main}
          textColor={textColor ? textColor : "white"}
          to={to}
        >
          {children}
        </SecondaryButton>
      </div>
    </>
  )
}

export default DialogBottomButton;
