import React, { useRef } from "react";
import { Container, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Text from "../data-display/text";
import InlineLink from "../data-display/inline-link";
import { Link } from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"
import PlayStore from "../../images/google_playstore.svg"
import AppStore from "../../images/apple-appstore.svg"
import iPhone from "../../images/iphone.jpg";
import SydeLogo from "../../images/sydework.png";

const Footer = () => {

    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isBrowser = typeof window !== 'undefined';
    return <>
        <div style={{ padding: "100px 0", position: "relative",display: isBrowser && window.location.pathname.includes("/tasks") || isBrowser && window.location.pathname.includes("/my-tasks") || isBrowser && window.location.pathname.includes("/account") ? "none" : ""  }}>
            <Container maxWidth={'lg'}>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Text bold variant={"h6"}>
                            On the move?
                        </Text>
                        <div style={{ height: "12px" }} />
                        <Text regular variant={"h6"} style={{ maxWidth: "400px" }}>
                            Download the Sydetasker App and get the tasks you need completed or start earning money.
                        </Text>
                        <div style={{ height: "32px" }} />
                        <div style={{ display: "flex" }}>
                            <a href={"https://play.google.com/store/apps/details?id=ai.unicode.sydetasker"}>
                                <img loading="lazy" src={PlayStore} alt={"Download Sydetasker"} />
                            </a>
                            <div style={{ width: "18px" }} />
                            <a href={"https://apps.apple.com/in/app/sydetasker/id1502353211#?platform=iphone"}>
                                <img loading="lazy" src={AppStore} alt={"Download Sydetasker"} />
                            </a>
                        </div>
                    </Grid>
                    {!smDown &&
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                            <img loading="lazy" alt={"Download the Sydetasker App"} src={iPhone} style={{ height: "85%", position: "absolute", bottom: "0" }} />
                            </div>
                        </Grid>
                    }
                </Grid>
            </Container>
        </div>
        <div style={{ width: "100%", padding: "32px 0", background: "#120730",display:isBrowser && window.location.pathname.includes("/tasks") || isBrowser && window.location.pathname.includes("/my-tasks") || isBrowser && window.location.pathname.includes("/account") ? "none" : ""  }}>
            <Container maxWidth={'lg'}>
                <Grid container spacing={4}>
                    <Grid item lg={3} xl={3}>
                        <Link to='/'>
                            <div style={{ display: "flex", alignItems: "center", margin: "0 auto" }}>
                                <Text variant={"h6"} style={{ transform: "translateY(-1px)" }} black>
                                    <InlineLink variant={"primary"} to={"/"} style={{ color: "white" }}>
                                        Sydetasker
                                    </InlineLink>
                                </Text>
                            </div>
                        </Link>
                        <div style={{ height: "18px" }} />
                        <Text variant={"body1"} style={{ color: "white", opacity: "0.8", height: "85%" }} regular>Connect with trusted Taskers with the skills you need</Text>
                    </Grid>
                    <Grid item lg={2} xl={2}>
                        <Text variant={"overline"} medium style={{ color: "white" }}>Company</Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink  to={"/about-us"} style={{ color: "white" }} variant={"secondary"}>About us</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink  to={"/contact-us"} style={{ color: "white" }} variant={"secondary"}>Contact us</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink  to={"/tasks"} style={{ color: "white" }} variant={"secondary"}>Browse Tasks</InlineLink></Text>
                        {/*<PostTaskWrapper>*/}
                        {/*    <Text variant={"body2"} medium><InlineLink style={{ color: "grey" }} variant={"secondary"}>Post a Task</InlineLink></Text>*/}
                        {/*</PostTaskWrapper>*/}
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink  to={"/login"} style={{ color: "white" }} variant={"secondary"}>Login</InlineLink></Text>
                    </Grid>
                    <Grid item lg={2} xl={2}>
                        <Text variant={"overline"} medium style={{ color: "white", marginBottom: "6px" }}>Legal</Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={"/terms"} style={{ color: "white" }} variant={"secondary"}>Terms of Service</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={"/privacy-policy"} style={{ color: "white" }} variant={"secondary"}>Privacy Policy</InlineLink></Text>
                    </Grid>
                    <Grid item lg={2} xl={2}>
                        <Text variant={"overline"} medium style={{ color: "white", marginBottom: "6px",marginTop:"8px" }}>Popular Categories</Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={"/services/handyman"} style={{ color: "white" }} variant={"secondary"}>Handyman Services</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0" ,marginTop:"8px"}}><InlineLink to={"/services/admin"} style={{ color: "white" }} variant={"secondary"}>Admin Services</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={"/services/yard-work"} style={{ color: "white" }} variant={"secondary"}>Yard Work Services</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ lineHeight: "2.0" ,marginTop:"8px"}}><InlineLink to={"/services"} style={{ color: "white" }} variant={"secondary"}>All Services</InlineLink></Text>
                    </Grid>
                    <Grid item lg={4} xl={4}>
                        <Text variant={"overline"} medium style={{ color: "white", marginBottom: "6px" }}>Office Address</Text>
                        <Text variant={"caption"} component={"p"} regular style={{ color: "white", lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={""} style={{ color: "white" }} variant={"secondary"}>2266 N Prospect Ave.Suite 606G Milwaukee, WI, 53202</InlineLink></Text>
                        {/* <Text variant={"caption"} component={"p"} regular style={{ color: "white", lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={""} style={{ color: "white" }} variant={"secondary"}>Suite 606G</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ color: "white", lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={""} style={{ color: "white" }} variant={"secondary"}>Milwaukee, WI, 53202</InlineLink></Text> */}
                    </Grid>
                    <Grid item lg={8} xl={8} style={{display:'flex', justifyContent:'flex-end'}}>
                        <br/>
                        <Text variant={"caption"} component={"p"} regular style={{ color: "white", lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={'https://www.zetaton.com '} style={{ color: "white" }} variant={"secondary"}>Powered by Zetaton</InlineLink></Text>
                        {/* <Text variant={"caption"} component={"p"} regular style={{ color: "white", lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={""} style={{ color: "white" }} variant={"secondary"}>Suite 606G</InlineLink></Text>
                        <Text variant={"caption"} component={"p"} regular style={{ color: "white", lineHeight: "2.0",marginTop:"8px" }}><InlineLink to={""} style={{ color: "white" }} variant={"secondary"}>Milwaukee, WI, 53202</InlineLink></Text> */}
                    </Grid>
                </Grid>
                <div style={{ margin: "24px 0", background: "rgb(70,70,80)", height: "1px" }} />
                <Grid container spacing={4} justify={"space-between"} alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div style={{ display: "flex", alignItems: "center", margin: "0 auto" }}>
                        <img loading="lazy" src={SydeLogo} alt={"Sydetasker Logo"} style={{ filter: "invert(1) grayscale(1) brightness(1.5)", marginRight: "16px", opacity: "0.7" }} height={22} />
                            <Text variant={"body2"} style={{ color: "white", opacity: "0.5" }} medium>
                                © {new Date().getFullYear()} Sydetasker LLC. All rights reserved.
                            </Text>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <div style={{ display: "flex", height: "100%", alignItems: "center", width: "100%", justifyContent: smDown ? "center" : "flex-end" }}>
                            <InlineLink disableArrow variant={"secondary"} to={"https://www.facebook.com/Sydetasker2020"}>
                                <FaFacebook style={{ color: "white" }} size={20} />
                            </InlineLink>
                            <div style={{ width: "18px" }} />
                            <InlineLink disableArrow variant={"secondary"} to={"https://www.instagram.com/sydetaskerapp/"}>
                                <FaInstagram style={{ color: "white" }} size={20} />
                            </InlineLink>
                            <div style={{ width: "18px" }} />
                            <InlineLink disableArrow variant={"secondary"} to={"https://www.linkedin.com/company/sydetasker"}>
                                <FaLinkedin style={{ color: "white" }} size={20} />
                            </InlineLink>
                            <div style={{ width: "24px" }} />
                            <a href={"https://play.google.com/store/apps/details?id=ai.unicode.sydetasker"}>
                            <img  loading="lazy" src={PlayStore} alt={"Download Sydetasker on Google Playstore"} height={32} style={{ filter: "invert(1) grayscale(1)", opacity: "0.7", borderRadius: "5px" }} />
                            </a>
                            <div style={{ width: "18px" }} />
                            <a href={"https://apps.apple.com/in/app/sydetasker/id1502353211#?platform=iphone"}>
                            <img loading="lazy" src={AppStore} alt={"Download Sydetasker on Apple App Store"} height={32} style={{ filter: "invert(1) grayscale(1)", opacity: "0.7", borderRadius: "5px" }} />
                            </a>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </>
}

export default Footer;