import {
    SET_USER,
    UPDATE_ADDRESS,
    UPDATE_USER,
    CLEAR_ADDRESS,
    UserActionTypes,
    UserState,
    UPDATE_USERS_DATA
} from "../types/user-type";

const initialState: UserState = {
    loading: true,
    addressLoading: true,
    userData: null,
    usersData:[],
    isAuthenticated: false,
    isVerified: false,
    providers: null,
    addresses: [],
    defaultBillingAddressID: null,
    defaultTaskAddressID: null
};

export function userDataReducer (state = initialState, action: UserActionTypes): UserState {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                userData: action.payload.userData,
                isAuthenticated: action.payload.user!==null && action.payload.userData!==null,
                isVerified: ((action.payload.user!==null && action.payload.user.emailVerified) || (action.payload.user?.providerData.some((p) => p.providerId.match(`^(google|facebook|apple)/i`)))),
                loading: false,
                providers: (action.payload.user!==null ? action.payload.user.providerData : null),
            };
        case UPDATE_USER:
            return {
                ...state,
                userData: action.payload.userData ? action.payload.userData : state.userData,
            };
        case UPDATE_ADDRESS:
            return {
                ...state,
                defaultBillingAddressID: action.payload.defaultBillingAddressID ? action.payload.defaultBillingAddressID : state.defaultBillingAddressID,
                defaultTaskAddressID: action.payload.defaultTaskAddressID ? action.payload.defaultTaskAddressID : state.defaultTaskAddressID,
                addresses: action.payload.addresses ? action.payload.addresses : state.addresses,
                addressLoading: action.payload.addressLoading
            };
            case UPDATE_USERS_DATA:
                return {
                    ...state,
                    usersData: action.payload ? action.payload : state.usersData
                };
            case CLEAR_ADDRESS:
            return {
                ...state,
                defaultBillingAddressID: '',
                defaultTaskAddressID: '',
                addresses: [],
                addressLoading: false
            };
        default:
            return state
    }
}

export default userDataReducer;