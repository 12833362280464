import firebase from "firebase";
import {SET_USER, UPDATE_ADDRESS, UPDATE_USER,CLEAR_ADDRESS, UserActionTypes,UPDATE_USERS_DATA} from "../types/user-type";
import {UserData} from "../../models/user-data";
import Address from "../../models/address";


export function setUser({user, userData} : {user: firebase.User | null, userData: UserData}): UserActionTypes {
    return {
        type: SET_USER,
        payload: { user: user, userData: userData }
    }
}

export function updateUser(userData: UserData): UserActionTypes {
    return {
        type: UPDATE_USER,
        payload: {
            userData
        }
    }
}


export function updateUsersData(usersData: UserData[]): UserActionTypes {
    return {
        type: UPDATE_USERS_DATA,
        payload: usersData??[]
    }
}

export function updateAddress({addresses, defaultBillingAddress, defaultTaskAddress}: {addresses?: Address[], defaultBillingAddress?: string, defaultTaskAddress?: string}): UserActionTypes {
    return {
        type: UPDATE_ADDRESS,
        payload: {
            addresses: addresses,
            defaultBillingAddressID: defaultBillingAddress,
            defaultTaskAddressID: defaultTaskAddress,
            addressLoading: false
        }
    }
}
export function clearAddress(): UserActionTypes {
    return {
        type: CLEAR_ADDRESS,
        payload: {}
    }
}

// export function setUserCards({cards}:{card:Card[]}){
//     return {
//         type:SET_USER_CARDS,
//         payload:{
//             cards
//         }
//     };
// }