import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import { privateUsers } from "../../utils/firebase.utils";
import Address from "../../models/address";
import { FiInfo } from "react-icons/fi";
import DialogWrapper from "../dialogs/dialog-wrapper";
import { useTheme } from "@material-ui/core";
import Text from "../data-display/text";
import { FaPlus, FaPlusCircle, FiMapPin, FiSearch } from "react-icons/all";
import ListContainer from "../list-handlers/list-container";
import AddressItem from "../list-handlers/address-item";
import FormInput from "../forms/form-input";
import FormErrorText from "../data-display/form-error-text";
import FormWrapper from "../forms/form-wrapper";
import MenuWrapper from "../menu-popper/menu-wrapper";
import MenuListItem from "../menu-popper/menu-list-item";
import CircularProgressBar from "../progress-bars/circular-progress-bar";
import DialogBottomButton from "../dialogs/dialog-bottom-button";
import Fade from "@material-ui/core/Fade";
import { updateAddress, clearAddress } from "../../redux/actions/user-actions";

const AddressHandler = ({ type, height, error = false, addressToEdit ,updateAddressToEdit}: { type: "task" | "edit-task" | "billing"; height?: string; error?: boolean; addressToEdit?: Address , updateAddressToEdit?:(address:Address)=>void}) => {
  const { addressLoading, addresses, userData, defaultBillingAddressID, defaultTaskAddressID } = useSelector((state: RootState) => state.userData);
  const dispatch = useDispatch();
  const theme = useTheme();

  const [addressDialog, setAddressDialog] = useState<boolean>(false);
  const [dialog, setDialog] = useState<string>("loading");
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [address, setAddress] = useState<Address>(null);
  const [editAddress, setEditAddress] = useState<Address>(null);
  const [autoComplete, setAutoComplete] = useState(null);
  const [placeDetails, setPlaceDetails] = useState(null);
  const [addressesList, setAddressesList] = useState(addresses);
  const  supportedZipCodes =
   ['77536','77571','77058','77511','77539','77546','77565','77573','77574','77598',
  '77015','77058','77059','77501','77502','77503','77504','77505','77506','77507',
    '77508','77536','77586','77047','77089','77581','77584','77588','77469','77478',
    '77479','77487','77496','77498','77493','77494','77071','77459','77477','77479',
    '77489','77545','77401','77402','77354','77375','77380','77381','77382','77384',
    '77385','77387','77389','77393','77301','77302','77303','77304','77305','77316',
    '77318','77356','77384','77385','77520','77521','77522','77523','77089','77546',
    '77548','77549','77598','77550','77551','77552','77553','77554','77555',
    '77566','77571','77572','77417','77469','77471','77510','77518','77539',
    '77554','77568','77590','77591','77592','77044','77338','77346','77396',
    '77015','77049','77530','77083','77373','77383'];
   const supportedCities =
   {'tx':['houston','deer park','league city','pasadena','pearland','sugar land',
   'katy','missouri city','bellaire','the woodlands','conroe','baytown','friendswood',
   'galveston','lake jackson','la porte','rosenberg','texas city','atascocita',
     'channelview','mission bend','spring']}; 

useEffect(()=>{
  if(addressToEdit != editAddress){
    setEditAddress(addressToEdit);
  }
},[addressToEdit])

  useEffect(() => {
    const googleMaps = window["google"];
    const maps = googleMaps?.maps;
    if (maps?.places) {
      setAutoComplete(new maps.places.AutocompleteService());
      setPlaceDetails(new maps.places.PlacesService(document.createElement("div")));
    }
    if (addressLoading) {
      privateUsers
        .doc(userData.uid)
        .get()
        .then(async (privateDoc) => {
          let data = privateDoc.data();
          let addressList: Address[] = [];
          let addressSnapshots = await privateUsers.doc(userData.uid).collection("addresses").get();
          if (addressSnapshots.size > 0) {
            addressSnapshots.forEach((doc) => {
              let addressData = doc.data();
              addressData.id = doc.id;
              addressList.push(new Address(addressData));
            });
            setDialog("select-address");
          } else {
            setDialog("add-address");
          }
          dispatch(
            updateAddress({
              addresses: addressList,
              defaultTaskAddress: data?.defaultTaskAddress?.toString() ?? "",
              defaultBillingAddress: data?.defaultBillingAddress?.toString() ?? ""
            })
          );
        });
    }
  }, []);

  useEffect(() => {
    setAddressesList(addresses);
  }, [addresses])


  const openDialog = () => {
    if (addresses.length > 0) setDialog("select-address");
    else setDialog("add-address");
    setAddressDialog(true);
  };

  const closeDialog = () => {
    setDialog("loading");
    setAddress(null);
    setQuery("");
    setResults([]);
    setAddressDialog(false);
  };

  const returnToRoot = () => {
    if (dialog === "add-address" && addresses.length > 0) {
      setDialog("");
      setTimeout(() => {
        setDialog("select-address");
      }, 450);
    } else if (dialog === "confirm-address") {
      setDialog("");
      setTimeout(() => {
        setDialog("add-address");
      }, 450);
    } else {
      closeDialog();
    }
  };

  const changeDialog = (value: string) => {
    setDialog("");
    setTimeout(() => {
      setDialog(value);
    }, 450);
  };


  const onValueChange = (values) => {
    if (values["address"]) {
      setQuery(values["address"].trim());
      clearTimeout(timer);
      if (values["address"].trim().length > 0) {
        setTimer(
          setTimeout(() => {
            getResults(values["address"].trim()).then();
          }, 500)
        );
      } else {
        setResults([]);
        setLoading(false);
      }
    } else {
      setQuery("");
      setLoading(false);
      setResults([]);
    }
  };

  
  // const deleteAddress = async (address) => {
  //   const docRef = await privateUsers.doc(userData?.uid).collection("addresses");
  //   let a: Address[] = [...addresses];
  //   for (let i = 0; i < addresses.length; i++) {
  //     const element = addresses[i]
  //     if (address.id === element?.id) {
  //       if (addresses.length > 1) {
  //         docRef.doc(address.id).delete();
  //         dispatch(
  //           updateAddress({
  //             addresses:addresses.splice(i,1)
  //           })
  //         );      
  //       } else {
  //         docRef.doc(address.id).delete();
  //         a = [];
  //         dispatch(clearAddress());
  //       }

  //     }

  //   }



  // }

  const RenderAddresses = () => {
    return (<ListContainer>

      {addresses.length > 0 && addresses.map((address, i) => {
        return (
          <AddressItem
            key={i + "address"}
            address={address}
            // deleteAddress={(address) => deleteAddress(address)}
            onClick={() => {
              if (type === "task" || type === "edit-task") {
                console.log("type:",type);
                privateUsers.doc(userData.uid).set({ defaultTaskAddress: address.id }, { merge: true }).then();
                dispatch(updateAddress({ defaultTaskAddress: address.id }));
                // if(udpateTaskAddress && type==="edit-task"){
                //   udpateTaskAddress(address);
                // }
                if(updateAddressToEdit){
                  updateAddressToEdit(address);
                }
                closeDialog();
              } else {
                privateUsers.doc(userData.uid).set({ defaultBillingAddress: address.id }, { merge: true }).then();
                dispatch(updateAddress({ defaultBillingAddress: address.id }));
                closeDialog();
              }
            }}
          />
        );
      })}

      <div
        style={{ background: "white", width: "100%", boxSizing: "border-box", padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer" }}
        onClick={() => {
          changeDialog("add-address");
        }}
      >
        <FaPlus style={{ color: theme.palette.primary.main, marginRight: "16px" }} size={16} />
        <Text variant={"body2"} style={{ color: theme.palette.primary.main }} bold selectable={false}>
          Add Address
        </Text>
      </div>
    </ListContainer>)
  }




  const getResults = async (q: string) => {
    setLoading(true);
    // TODO Location Redux
    // if(currentLocation) {
    //    latitude = tasksProvider.currentLocation.latitude.toString();
    //    longitude = tasksProvider.currentLocation.longitude.toString();
    // }

    autoComplete.getPlacePredictions({ types: ["address"], componentRestrictions: { country: "us" }, input: q }, (p) => {
      setResults(p ?? []);
      setLoading(false);
    });
  };

  const selectAddress = (placeId: string) => {
    try {
      placeDetails.getDetails({ placeId: placeId }, (place) => {
        let newAddress: Address = new Address();
        let geometry = place["geometry"]["location"];
        newAddress.lat = geometry.lat();
        newAddress.lng = geometry.lng();

        place["address_components"].forEach((element) => {
          let types: string[] = element["types"];

          if (types.findIndex((element) => element === "street_number") !== -1) newAddress.addressLineOne = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "route") !== -1) newAddress.addressLineOne = (newAddress.addressLineOne ? newAddress.addressLineOne + " " : "") + element["short_name"] ?? "";

          newAddress.addressLineOne = newAddress.addressLineOne.trim();

          if (types.findIndex((element) => element === "locality") !== -1) newAddress.city = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "administrative_area_level_1") !== -1) newAddress.state = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "country") !== -1) newAddress.country = element["short_name"] ?? "";

          if (types.findIndex((element) => element === "postal_code") !== -1) newAddress.zipCode = element["short_name"] ?? "";
        });

        // Confirm we have the correct city details (Exceptions in NY, USA)
        if (newAddress.city === null || newAddress.city === "") {
          const { maps } = window["google"];
          if (maps.places) {
            let geoCoder = new maps.places.GeoCoder();
            geoCoder.geocode(
              {
                location: {
                  lat: newAddress.lat,
                  lng: newAddress.lng
                }
              },
              (result) => {
                newAddress.city = result[0]["address_components"][0]["short_name"] ?? "";
                newAddress.placeId = place["place_id"];
                setAddress(newAddress);
              }
            );
          }
        } else {
          newAddress.placeId = place["place_id"];
          setAddress(newAddress);
        }
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const saveAddress = (values) => {
    return new Promise(async (resolve, reject) => {
      address.addressLineOne = values["addressLineOne"];
      address.addressLineTwo = values["addressLineTwo"];
      address.city = values["city"];
      address.state = values["state"];
      address.zipCode = values["zipCode"];
      const regTest = new RegExp("^[0-9]+$");
      if (regTest.test(address.zipCode)) {
        let docReference = await privateUsers.doc(userData.uid).collection("addresses").add(address.toJson());
        let addressSnapshots = await privateUsers.doc(userData.uid).collection("addresses").get();

        let addressList = [];
        let defaultBillingAddressID = null;
        let defaultTaskAddressID = null;
        if (addressSnapshots.size > 0) {
          addressSnapshots.forEach((doc) => {
            let addressData =new Address(doc.data());
            addressData.id = doc.id;
            if(type==="edit-task"&&updateAddressToEdit){
              updateAddressToEdit(addressData);
            }
            addressList.push(new Address(addressData));
          });
        }
        if (type === "billing") {
          await privateUsers.doc(userData.uid).set({ defaultBillingAddress: docReference.id }, { merge: true });
          defaultBillingAddressID = docReference.id;
        } else {
          await privateUsers.doc(userData.uid).set({ defaultTaskAddress: docReference.id }, { merge: true });

          defaultTaskAddressID = docReference.id;

        }
        dispatch(
          updateAddress({
            addresses: addressList,
            defaultTaskAddress: defaultTaskAddressID,
            defaultBillingAddress: defaultBillingAddressID
          })
        );
        closeDialog();
      } else {
        reject("The zip code should be only numeric");
      }
    });
  };

  return (
    <div>
      {!addressLoading ? (
        <div>
          {(type === "task" && !defaultTaskAddressID) || (type === "billing" && !defaultBillingAddressID) ? (
            <div onClick={openDialog} style={{ border: error ? "2px solid rgba(235, 80, 60, 0.5)" : "2px solid rgb(232,232,232,0.9)", background: "white", borderRadius: "6px", padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.02)" }}>
              <FaPlusCircle style={{ color: theme.palette.primary.main, marginRight: "16px" }} size={15} />
              <Text variant={"body2"} style={{ color: theme.palette.primary.main }} medium selectable={false}>
                {addresses.length > 0 ? `Select ${type === "task" ? "Task" : "Billing"} Address` : `Add ${type === "task" ? "Task" : "Billing"} Address`}
              </Text>
            </div>
          ) : type === "task" ? (
            <AddressItem address={addresses.find((a) => a.id === defaultTaskAddressID)} onClick={openDialog} editable last />
          ) : type === "edit-task" ? (
            <AddressItem address={editAddress} onClick={openDialog} editable last />
          ) : (
            <AddressItem  address={addresses.find((a) => a.id === defaultBillingAddressID)} onClick={openDialog} editable last />
          )}
          {(type == "edit-task" || type === "task") && <div style={{ display: "flex", marginTop: "6px", marginLeft: "8px", }}>
            <FiInfo style={{ color: "grey" }} size={14} />
            <Text variant={"caption"} style={{ color: "grey", marginLeft: "8px" }} medium>
              Exact task address is not shown publicly until a task is assigned.
            </Text>
          </div>}
        </div>
      ) : (
        <div style={{ border: "2px solid rgb(232,232,232,0.9)", background: "white", borderRadius: "6px", padding: "12px 16px", display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.02)" }}>
          <Text variant={"body2"} style={{ color: "grey" }} medium selectable={false}>
            Retrieving Addresses...
          </Text>
        </div>
      )}
      <DialogWrapper height={height} disableBackdrop root={dialog === "select-address" || dialog === "loading" || (!addressLoading && addresses.length === 0 && dialog === "add-address")} onReturn={returnToRoot} title={(dialog === "add-address" || dialog === "confirm-address" || dialog === "") ? "Add Address" : "Select Address"} open={addressDialog} onClose={closeDialog}>
        <div style={{ height: "500px" }}>
          <div style={{ height: "2px" }} />
          <Fade in={dialog === "select-address"} mountOnEnter unmountOnExit>
            <div>
              <Text variant={"overline"} style={{ opacity: "0.6", margin: "12px 0" }}>
                Saved Addresses
              </Text>
              <RenderAddresses />
            </div>
          </Fade>
          <Fade in={dialog === "confirm-address" || dialog === "add-address"} mountOnEnter unmountOnExit>
            <div>
              <Fade in={dialog === "add-address"} mountOnEnter unmountOnExit>
                <FormWrapper
                  onSubmit={() => {
                    return new Promise(() => { });
                  }}
                  onValueChange={onValueChange}
                  initialValues={{ address: query ?? "" }}
                  style={{ position: "relative" }}
                >
                  <MenuWrapper
                    live
                    disableToggle
                    items={
                      <div style={{ height: "auto" }}>
                        {query.trim().length === 0 ? (
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px" }}>
                            <FiSearch style={{ color: "grey", marginRight: "12px" }} size={14} />
                            <Text variant={"caption"} style={{ color: "grey", marginRight: "12px" }}>
                              Start Typing...
                            </Text>
                          </div>
                        ) : (
                          <div style={{ minHeight: "300px" }}>
                            <div style={{ opacity: !loading ? 1 : 0, transition: "0.3s" }}>
                              {results && results?.length > 0 ? (
                                results?.map((r) => {
                                  return (
                                    <MenuListItem
                                      key={r["place_id"]}
                                      icon={<FiMapPin size={14} style={{ flexShrink: 0 }} />}
                                      onClick={() => {
                                        setAddress(null);
                                        selectAddress(r["place_id"]);
                                        setDialog("confirm-address");
                                      }}
                                    >
                                      <p style={{ margin: 0, display: "flex", flexDirection: "column" }}>
                                        <Text component={"span"} variant={"body2"}>
                                          {r["structured_formatting"]["main_text"]}
                                        </Text>
                                        <Text component={"span"} variant={"body2"} style={{ color: "grey" }}>
                                          {r["structured_formatting"]["secondary_text"]}
                                        </Text>
                                      </p>
                                    </MenuListItem>
                                  );
                                })
                              ) : ((!loading && results?.length === 0) ?
                                <MenuListItem>
                                  <Text variant={"body2"}>We couldn't find anything match your search.</Text>
                                </MenuListItem>
                                :
                                <div />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    }
                    style={{ width: "100%" }}
                  >
                    <FormInput autoComplete={"on"} name={"address"} placeholder={"Search Address"} endAdornment={<FiSearch style={{ color: "grey", marginRight: "12px" }} size={18} />} />
                  </MenuWrapper>
                </FormWrapper>
              </Fade>
              <Fade in={dialog === "confirm-address"} style={{ transitionDelay: "0.4s" }} mountOnEnter unmountOnExit>
                <div style={{ height: "100%" }}>
                  {address ? (
                    <FormWrapper
                      onSubmit={saveAddress}
                      onValueChange={onValueChange}
                      initialValues={{
                        addressLineOne: address.addressLineOne,
                        addressLineTwo: address.addressLineTwo,
                        city: address.city,
                        state: address.state,

                      }}
                    >
                      {(address?.state?.toLowerCase() === "wi" || address?.state?.toLowerCase() === "mn" || address?.state?.toLowerCase() === "il" || address?.state?.toLowerCase() === "tx") || type === "billing" || 
                      supportedCities[address?.state?.toLowerCase()]?.includes(address?.city?.toLowerCase()) || supportedZipCodes?.includes(address?.zipCode)  ? <div>
                        <FormInput name={"addressLineOne"} placeholder={"Street Address"} required />
                        <FormInput name={"addressLineTwo"} placeholder={"Apt. / Suite (Optional)"} />
                        <div style={{ display: "flex" }}>
                          <FormInput name={"city"} rootStyle={{ flexGrow: "unset" }} placeholder={"City"} required />
                          <div style={{ width: "16px", flexShrink: 0 }} />
                          <FormInput name={"state"} maxLength={"2"} rootStyle={{ width: "64px" }} style={{ textTransform: "uppercase" }} placeholder={"State"} required />
                          <div style={{ width: "16px", flexShrink: 0 }} />
                          <FormInput name={"zipCode"} rootStyle={{ width: "112px" }} maxLength={"5"} placeholder={"Zip"} required />
                        </div>
                        <FormErrorText style={{ marginTop: "0px" }} />
                        <div style={{ height: "64px" }} />
                      </div> :
                        <MenuListItem>
                          <Text variant={"body2"}>We do not provide services in your area yet. Sorry for the inconvenience.</Text>
                          <div style={{ height: "64px" }} />
                        </MenuListItem>
                      }
                      <DialogBottomButton disabled={(address?.state?.toUpperCase() !== "WI" && address?.state?.toUpperCase() !== "MN" && address?.state?.toUpperCase() !== "IL" && address?.state?.toUpperCase() !== "TX") && !(type === "billing")} >Save</DialogBottomButton>
                    </FormWrapper>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-star",
                        alignItems: "center",
                        padding: "24px 0",
                        height: "300px"
                      }}
                    >
                      <CircularProgressBar />
                    </div>
                  )}
                </div>
              </Fade>
            </div>
          </Fade>
        </div>
      </DialogWrapper>
    </div>
  );
};

export default AddressHandler;
