import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import { useLocation } from "@reach/router";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { RootState } from "../redux/index.reducers";
import { navigate } from "gatsby";


let config;

if (process.env.GATSBY_APP_ENV === "production") {
  config = {
    apiKey: "AIzaSyBwikRgeisiemSFd8-mMb6aLAwqVIhH8mg",
    authDomain: "sydetasker.com",
    databaseURL: "https://sydework-api.firebaseio.com",
    projectId: "sydework-api",
    storageBucket: "sydework-api.appspot.com",
    messagingSenderId: "681416980342",
    appId: "1:681416980342:web:04f92e0fb9fbee09",
  };
} else {
  config = {
    apiKey: "AIzaSyBzaVRTzuHupOUHjdDHZfYPJZlhcUyDfBY",
    authDomain: "sydework-dev.firebaseapp.com",
    databaseURL: "https://sydework-dev.firebaseio.com",
    projectId: "sydework-dev",
    storageBucket: "sydework-dev.appspot.com",
    messagingSenderId: "460196080444",
    appId: "1:460196080444:web:0fd2f5851521a967664c39",
    measurementId: "G-PS44QYP4P5",
  };
}

// firebase.initializeApp(config);
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}else {
  firebase.app(); // if already initialized, use that one
}
firebase.analytics();

// Google login
const GoogleProvider = new firebase.auth.GoogleAuthProvider();

export const SignInWithGoogle = () => {
  return auth.signInWithRedirect(GoogleProvider);
};
// Apple login
const AppleProvider = new firebase.auth.OAuthProvider("apple.com");

export const SignInWithApple = () => {
  return auth.signInWithRedirect(AppleProvider);
};

// Facebook login
let FacebookProvider = new firebase.auth.FacebookAuthProvider();

export const SignInWithFacebook = () => {
  return auth.signInWithRedirect(FacebookProvider);
};

// Registration
export const RegisterWithEmailAndPassword = ({ email, password }: { email: string; password: string }) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

// Logout
export const Logout = () => {
  return auth.signOut();
};

// Firebase Instances
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

// Collections
export const users = firebase.firestore().collection("users");
export const privateUsers = firebase.firestore().collection("users_stripe");
export const tasks = firebase.firestore().collection("tasks");
export const AdminMessage = firebase.firestore().collection("messaging_with_admin");
export const notifications = (uid: string) => firebase.firestore().collection("users").doc(uid).collection("notifications");
export const myTaskAttachments = (uid: string) => firebase.storage().ref(`/taskAttachments/${uid}`);
export const myPortfolio = (uid: string) => firebase.storage().ref(`/portfolioImages/${uid}`);


//routes
export const NoAuthRoute=({component:Component, redirect=true ,...rest})=>{
 const location = useLocation();
 const {isAuthenticated}=useSelector((state:RootState)=>state.userData);
 if(!isAuthenticated||!redirect){
    return(
      <Component {...rest}/>
    )
 }else{
   if(typeof window != undefined){
     navigate((location && queryString.parse(location.search??"")?.redirect)? (queryString.parse(location.search).redirect as string):"/login");
   }
 }
}



export default firebase;
