import makeStyles from "@material-ui/core/styles/makeStyles";
import {SwitchProps, Theme} from "@material-ui/core";
import React from "react";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme: Theme) => {
    return ({
        root: {
            width: 60,
            height: 30,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: "7px",
            color: theme.palette.common.white,
            transition: "0.4s",
            transitionTimingFunction: "cubic-bezier(0.23, 1.0, 0.32, 1.0)",
            '&$checked': {
                transform: 'translateX(30px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 16,
            height: 16,
            boxShadow: '0 4px 5px rgba(66,66,66,0.15)',
        },
        track: {
            borderRadius: 20,
            opacity: 1,
            backgroundColor: "rgba(224,224,224)",
        },
        checked: {},
    });
})


const FormSwitch = (props: SwitchProps) => {

    const classes = useStyles();

    return (
        <Switch
            {...props}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
            }}
        />
    )
}

export default FormSwitch;