import React, { useEffect, useState } from "react";
import NavBar from "../navigation-bar/navigation-bar";
import { Container, createMuiTheme, Grid, MuiThemeProvider } from "@material-ui/core";
import Footer from "../footer/footer";
import Text from "../data-display/text";
import firebase from "gatsby-plugin-firebase";
import { setUser } from "../../redux/actions/user-actions";
import { auth, privateUsers, users } from "../../utils/firebase.utils";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { UserActionTypes } from "../../redux/types/user-type";
import { UserData } from "../../models/user-data";
import "./layout.css";
import { RootState } from "../../redux/index.reducers";
import SydetaskerIcon from "../../images/sydework.png";
import OfferLoadingProvider from "../task-view/hooks/offer-state";
import NameVerification from "../verify-name/name-verification";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "../../utils/constant.urls";
import AppDialog from "../dialogs/download-app";
import DeactivateAccount from "../deactivate/deactivate-account"
import { useMatch } from '@reach/router';
const stripePromise = loadStripe(STRIPE_KEY);

const MuiTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#4E3EFD"
        },
        text: {
            primary: "rgb(40,40,40)"
        },
        grey: {
            "50": ""
        },
        background: {
            default: "rgb(247, 248, 251)",
            paper: "rgb(255,255,255)"
        }
    }
});

const Layout = ({ children, location }: { children: React.ReactNode, location: any }) => {
    // const canonicalUrl = location.pathname;

    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch<Dispatch<UserActionTypes>>();
    const taskMatch = useMatch("/tasks/:id/*");
    const browseTaskMatch = useMatch("/tasks/");
    const myTasksMatch = useMatch("/my-tasks/:type/*");
    const messageMatch = useMatch("/messaging/:id");


    //   const { loading } = useSelector((state: RootState) => state.userData);



    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                let doc = await users.doc(user.uid).get();
                let userData;
                if (doc.exists) {
                    let data = doc.data();
                    data.uid = user.uid;

                    let privateDoc = await privateUsers.doc(user.uid).get();
                    if (privateDoc.exists) {
                        data.phoneNumber = privateDoc.data()["phoneNumber"];
                        data.birthDate = privateDoc.data()["birthDate"];
                        data.previouslyHired = privateDoc.data()["previouslyHired"];
                    }

                    userData = new UserData(data);
                    await users.doc(user.uid).set({lastAccessToApp: new Date()},{merge:true});
                } else {
                    let firstName;
                    let lastName;
                    if (user.displayName) {
                        firstName = user.displayName.split(" ")[0] ?? "";
                        lastName = user.displayName.split(" ").length > 1 ? user.displayName.split(" ")[1] : "";
                    }
                    let newUserData: UserData = new UserData({
                        uid: user.uid,
                        email: user.email,
                        firstName: firstName,
                        lastName: lastName
                    });
                    await users.doc(user.uid).set(newUserData.toPublicData());
                    await users.doc(user.uid).set({lastAccessToApp: new Date()},{merge:true});
                    userData = newUserData;
                }
                dispatch(setUser({ user: user, userData: userData }));
            } else {
                dispatch(setUser({ user: null, userData: null }));
            }
        });
    }, []);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                let doc = await firebase.firestore().collection('users').doc(user.uid).get();
                if (doc.exists) {
                    await firebase.firestore().collection('users_stripe').doc(user.uid).get();
                    setTimeout(() => {
                        setLoading(false);
                        setTimeout(() => {
                            setLoaded(true)
                        }, 310)
                    }, 200)
                }
            } else {
                setTimeout(() => {
                    setLoading(false);
                    setTimeout(() => {
                        setLoaded(true)
                    }, 310)
                }, 1000)
            }
        })
    }, []);

    return (
        <>
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MQ7TZLM" height="0" width="0" style={{ display: "none", visibility: "hidden" }} />
            </noscript>
            <MuiThemeProvider theme={MuiTheme}>
                <div className={"loader"} style={{ opacity: loading ? 1 : 0, display: loaded ? "none" : "flex" }}>
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <img loading={"lazy"}  src={SydetaskerIcon} height={64} alt={"Sydetasker"} style={{ marginBottom: '12px' }}/>
                        <Text variant={'caption'} style={{ color: "rgba(80,80,100,0.3)" }} medium>Sydetasker</Text>
                    </div>
                </div>
                <NavBar />
                <OfferLoadingProvider loadingState={true}>
                <NameVerification>
              <DeactivateAccount>
                <Elements stripe={stripePromise}>
                {children}
                <AppDialog/>
                </Elements>
                </DeactivateAccount>
                </NameVerification>
                </OfferLoadingProvider>
                {!taskMatch && !browseTaskMatch && !myTasksMatch && !messageMatch && <Footer /> }
                 </MuiThemeProvider>
        </>
    )
}

export default Layout;