import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DialogProps, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Text from "../data-display/text";
import { AiOutlineClose, IoMdArrowBack } from "react-icons/all";
import DialogTitle from "@material-ui/core/DialogTitle";
import useTheme from "@material-ui/core/styles/useTheme";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomIconButton from "../buttons/custom-icon-button";

const useStyles = makeStyles((theme: Theme) => ({
    dialog: {
        position: "relative",
        zIndex: 2600,
        background: theme.palette.background.default,
        boxSizing: "border-box",
        height: "auto",
        borderRadius: "10px",
        padding: "0",
        display: "flex",
        boxShadow: "none",
        flexDirection: "column",
        transition: theme.transitions.create(["box-shadow"], {
            easing: theme.transitions.easing.easeInOut,
            duration: "0.3s",
        }),
        [theme.breakpoints.down('xs')]: {
            height: "100%",
            borderRadius: "0px",
        }
    }
}));

interface DialogWrapperProps extends Omit<DialogProps, 'onSubmit'> {
    onClose: () => void,
    onReturn?: () => void,
    suffixIcon?: React.ReactNode,
    root?: boolean,
    disablePadding?: boolean,
    disableBackdrop?: boolean,
    height?: string
}

const DialogWrapper = ({ open, onClose, title, children, root, disablePadding, onReturn, suffixIcon = <div />, disableBackdrop = false, height = "500px" }: DialogWrapperProps) => {

    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const handleOnClick = () => {
        if (root) {
            onClose();
        } else {
            onReturn();
        }
    }
    return (
        <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth classes={{ paper: classes.dialog }}
            BackdropProps={{
                style: {
                    background: !disableBackdrop ? "rgba(0, 0, 0, 0.5)" : "rgb(0,0,0,0)"
                }
            }}
            style={{ zIndex: 2000 }} fullScreen={xsDown}>
            <DialogTitle style={{ borderBottom: "1.5px solid rgb(220,220,220)", position: "relative", padding: "12px 24px" }}>
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                        <CustomIconButton style={{ transform: "translateX(-3px)", marginRight: '12px' }} onClick={handleOnClick}>
                            <AiOutlineClose style={{ opacity: root ? 1 : 0, transform: `rotate(${root ? "0deg" : "-15deg"})`, transition: "0.3s" }} />
                            <IoMdArrowBack style={{ position: "absolute", opacity: !root ? 1 : 0, transform: `rotate(${!root ? "0deg" : "15deg"})`, transition: "0.3s" }} />
                        </CustomIconButton>
                        <Text variant="body1" black component={"span"}>
                            {title}
                        </Text>
                    </div>
                    <div style={{ marginLeft: "auto" }} />
                    {suffixIcon}
                </div>
            </DialogTitle>
            <DialogContent style={{ maxHeight: xsDown ? "unset" : height, padding: disablePadding ? "0px" : "auto" }}>
                {children}
            </DialogContent>
        </Dialog >
    )
}

export default DialogWrapper;


