export default class Address {
    id: string | null = "";
    placeId: string | null = "";
    addressLineOne: string | null = "";
    addressLineTwo: string | null = "";
    city: string | null = "";
    state: string | null = "";
    country: string | null = "";
    zipCode: string | null = "";
    lat: number | null = null;
    lng: number | null = null;


    constructor(data?:Partial<Address>) {
        this.id = data?.id ?? null;
        this.placeId = data?.placeId ?? null;
        this.addressLineOne = data?.addressLineOne ?? null;
        this.addressLineTwo = data?.addressLineTwo ?? null;
        this.city = data?.city ?? null;
        this.state = data?.state ?? null;
        this.country = data?.country ?? null;
        this.zipCode = data?.zipCode ?? null;
        this.lat = data?.lat ?? null;
        this.lng = data?.lng ?? null;
    }

    toJson(): Address {
        return Object.assign({}, this);
    }

    get primaryLine() {
        return this.addressLineOne;
    }

    get secondaryLine() {
        return this.city + ", " + this.state;
    }

    get secondaryLineWithZipCode() {
        return (this.city + ", " + this.state + " " + this.zipCode).trim();
    }
}
