import React, { cloneElement, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux"; 
import { Task, TaskOwner } from "../../models/task";
import { RootState } from "../../redux/index.reducers";
import DialogWrapper from "../../components/dialogs/dialog-wrapper";
import CircularProgressBar from "../../components/progress-bars/circular-progress-bar";
import PhoneNumberForm from "../../components/my-account-components/edit-account-forms/phone-number-form";
import SelectCategory from "./dialog-forms-post-task/select-category";
import TaskDetailsForm from "./dialog-forms-post-task/task-details-form";
import TaskDateForm from "./dialog-forms-post-task/task-date-form";
import TaskRequirementsForm from "./dialog-forms-post-task/task-requirements-form";
import DiscardTask from "./dialog-forms-post-task/discard-task";
import TaskBudgetForm from "./dialog-forms-post-task/task-budget-form";
import TaskAttachmentsForm from "./dialog-forms-post-task/task-attachments-form";
import Text from "../../components/data-display/text";
import TaskPosted from "../../images/images/task_posted.png";
import VerificationWrapper from "../../pages/verify-email/verification-wrapper";
import useTheme from "@material-ui/core/styles/useTheme";
import { useMediaQuery } from "@material-ui/core";
import InviteTaskers from "./dialog-forms-post-task/invite-taskers";
import Fade from "@material-ui/core/Fade";
import CustomIconButton from "../buttons/custom-icon-button";
import { RiDeleteBinLine } from "react-icons/ri";
import { auth } from "../../utils/firebase.utils";
import { navigate} from 'gatsby';


const PostTaskWrapper = ({ task, children, style, invitedTasker }: { task?: Task; children: React.ReactNode; style?: React.CSSProperties; invitedTasker?: string }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const { userData, isAuthenticated } = useSelector((state: RootState) => state.userData);
  const [postTaskDialog, setPostTaskDialog] = useState(false);
  const [dialog, setDialog] = useState<number>(0);
  const [newTask, setNewTask] = useState<Task>(new Task());
  const [newTaskId, setId] = useState(null);
  const [discardDialog, setDiscardDialog] = useState<boolean>(false);
  let user = auth?.currentUser;

  useEffect(() => {
    if (postTaskDialog && dialog === 0 && isAuthenticated) {
      let t = new Task();
      t.uid = userData.uid;
      t.taskOwner = new TaskOwner({ name: userData.name, profilePicture: userData.profilePicture });
      if (task) {
        t.category = task.category;
        t.title = task.title;
        t.description = task.description;
        t.requirements = task.requirements ?? [];
        setNewTask(t);
        setDialog(2);
      } else {
        setDialog(1);
        setNewTask(t);
      }
    }
  }, [isAuthenticated, postTaskDialog, dialog]);

  const openDialog = () => setPostTaskDialog(true);

  const closeDialog = (discarding = false) => {
    if (!discardDialog && dialog !== 0 && dialog !== 1 && dialog !== 8) {
      setDiscardDialog(true);
    } else {
      if (newTaskId) {
       navigate("/my-tasks/poster/" + newTaskId);
      }
      setPostTaskDialog(false);
      setTimeout(() => {
        setDialog(0);
        setDiscardDialog(false);
        setNewTask(new Task({}));

      }, 450);
    }
  };

  const goBack = () => {
    if (discardDialog) {
      setDiscardDialog(false);
    } else {
      setTimeout(() => {
        setDialog(dialog - 0.5);
        setTimeout(() => {
          setDialog(dialog - 1);
        }, 450);
      }, 100);
    }
  };

  const saveNewTaskId = (id: string) => {
    setId(id);
  };

  const goNext = () => {
    if (dialog === 6 && userData?.phoneNumber && userData?.phoneNumber.length > 3) {
      setTimeout(() => {
        setDialog(dialog + 1.5);
        setTimeout(() => {
          setDialog(dialog + 2);
        }, 450);
      }, 100);
    } else if (dialog === 8) {
      closeDialog();
    } else {
      setTimeout(() => {
        setDialog(dialog + 0.5);
        setTimeout(() => {
          setDialog(dialog + 1);
        }, 450);
      }, 100);
    }
  };

  const updateTask = (t: Task) => {
    setNewTask(t);
  };


  let processElements = (reactElements: Array<Exclude<React.ReactNode, boolean | null | undefined>>) => {
    reactElements.forEach((el, index) => {
      let element = el as ReactElement;
      reactElements[index] = cloneElement(element, {
        onClick: openDialog,
        key: index
      });
    });
    return reactElements;
  };

  let elements = React.Children.toArray(children);
  elements = processElements(elements);

  return (
    <div style={style}>
      {elements}
      {isAuthenticated && (
        <VerificationWrapper open={postTaskDialog} closeDialog={closeDialog}>
          <DialogWrapper height={"550px"}
            onClose={closeDialog}
            suffixIcon={
              (dialog !== 0 && dialog !== 1 && dialog !== 8) &&
              <CustomIconButton variant={"secondary"} onClick={() => setDiscardDialog(true)} >
                <RiDeleteBinLine />
              </CustomIconButton>
            }
            root={dialog === 0 || dialog === 1 || dialog === 8}
            onReturn={dialog.toString().includes(".") ? null : goBack}
            open={postTaskDialog} title={"Post a Task"}
          >
            <div style={{ height: xsDown ? "calc(100vh - 100px)" : "500px" }}>
              <div style={{ height: "2px" }} />
              {dialog === 0 && (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-star", alignItems: "center", padding: "24px 0", height: "300px" }}>
                  <CircularProgressBar />
                </div>
              )}
              <Fade in={dialog === 1} mountOnEnter unmountOnExit>
                <div style={{ height: dialog === 1 ? "100%" : "" }}>
                  <SelectCategory onContinue={goNext} updateTask={updateTask} task={newTask} />
                </div>
              </Fade>
              <Fade in={dialog === 2} mountOnEnter unmountOnExit>
                <div style={{ height: dialog === 2 ? "100%" : "" }}>
                  <TaskDetailsForm onContinue={goNext} updateTask={updateTask} task={newTask} />
                </div>
              </Fade>
              <Fade in={dialog === 3} mountOnEnter unmountOnExit>
                <div style={{ height: dialog === 3 ? "100%" : "" }}>
                  <TaskDateForm onContinue={goNext} updateTask={updateTask} task={newTask} />
                </div>
              </Fade>
              <Fade in={dialog === 4} mountOnEnter unmountOnExit>
                <div style={{ height: dialog === 4 ? "100%" : "" }}>
                  <TaskRequirementsForm onContinue={goNext} updateTask={updateTask} task={newTask} />
                </div>
              </Fade>
              <Fade in={dialog === 5} mountOnEnter unmountOnExit>
                <div style={{ height: dialog === 5 ? "100%" : "" }}>
                  <TaskAttachmentsForm onContinue={goNext} updateTask={updateTask} task={newTask} />
                </div>
              </Fade>
              <Fade in={dialog === 6} mountOnEnter unmountOnExit>
                <div style={{ height: dialog === 6 ? "100%" : "" }}>
                  <TaskBudgetForm onContinue={goNext} updateTask={updateTask} saveNewTaskId={saveNewTaskId} task={newTask} />
                </div>
              </Fade>
              {userData && (
                <Fade in={dialog === 7} mountOnEnter unmountOnExit>
                  <div style={{ height: dialog === 7 ? "100%" : "" }}>
                    <PhoneNumberForm
                      onClose={goNext}
                      isPostTask={true}
                      task={newTask}
                      saveNewTaskId={saveNewTaskId}
                      onContinue={goNext}
                    />

                  </div>
                </Fade>
              )}
              {userData && (
                <Fade in={dialog === 8} mountOnEnter unmountOnExit>
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div style={{ background: `url(${TaskPosted}) center center / cover`, height: userData.previouslyHired.length ? "150px" : "200px", width: userData.previouslyHired.length ? "150px" : "200px", marginTop: userData.previouslyHired.length ? "0px" : "50px" }} />
                      <Text variant={"body2"} bold style={{ color: "#707070", width: "300px", marginTop: "20px" }}>
                        Task has been posted !
                      </Text>
                      <Text variant={"body2"} bold style={{ color: "#707070", width: "300px", marginTop: "20px" }}>
                        Sit back and Relax. We will let you know as soon as you have any offers on your Task.
                      </Text>
                    </div>
                    <div style={{ height: "20px" }} />
                    {(userData.previouslyHired.length || invitedTasker) && <InviteTaskers taskId={newTaskId} invitedTasker={invitedTasker} />}
                  </div>
                </Fade>
              )}
              <Fade in={discardDialog} mountOnEnter unmountOnExit>
                <div style={{ height: "calc(100% - 64px)", width: "100%", position: "absolute", zIndex: 1400, top: 56, left: 0 }}>
                  <DiscardTask onContinue={closeDialog} onCancel={() => setDiscardDialog(false)} />
                </div>
              </Fade>
            </div>
          </DialogWrapper>
        </VerificationWrapper>
      )}
    </div>
  );
};

export default PostTaskWrapper;

export interface PostTaskProps {
  updateTask?: (task: Task) => void;
  task: Task;
  onContinue: () => void;
  saveNewTaskId?: (id: string) => void;
}
