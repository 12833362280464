export class Notification {
    object: string = '';
    objectId: string = '';
    slug_title:string='';
    body: string = '';
    notificationId: string = '';
    options: Record<string, string> = {};
    type: 'task_invite' | 'new_comment' | "new_reply" | "new_offer"
        | "offer_updated" | "assign" | "increase-price-by-poster" | "request_payment_release"|"verified_user_profile"
        | "payment_released" | "payout_created" | "payout_paid" | "new_review" | "transfer_created" | "task_update" | "missed_call" | "call"
        | "rejected_user_profile" | "new_message" |"reminder_alert_task" |"unread_message" |'unknown' = 'unknown';
    taskTitle: string = '';
    firstName: string = '';
    profilePicture: string = '';
    taskId: string = '';
    opened: boolean = false;
    uid: string = '';
    dynamicLink: string = '';
    createAt: Date = null;

    constructor(data?: { [field: string]: any }) {
        this.object = data?.object ?? '';
        this.slug_title = data?.slug_title ?? '';
        this.objectId = data?.objectId ?? '';
        this.body = data?.body ?? '';
        this.notificationId = data?.notificationId ?? '';
        this.options = data?.options ?? {};
        this.type = data?.type ?? 'unknown';
        this.taskTitle = data?.taskTitle ?? '';
        this.firstName = data?.username ?? "";
        this.profilePicture = data?.profilePicture ?? "";
        this.taskId = data?.taskId ?? '';
        this.opened = data?.opened ?? false;
        this.uid = data?.uid ?? '';
        this.dynamicLink = data?.dynamicLink ?? '';
        this.createAt = data?.createAt ? (typeof data?.createAt === "string" ? new Date(data.createAt) : (data.createAt instanceof Date ? data.createAt : new Date(data.createAt.toDate().toString()))) : null;
    }

    toJson(): Notification {
        return Object.assign({}, this);
    }
}
