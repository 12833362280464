import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import Text from "../data-display/text";
import {Link} from "gatsby";
import ButtonBase, {ButtonBaseProps} from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import useTheme from "@material-ui/core/styles/useTheme";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
    primary: {
        position: "relative",
        marginLeft: "auto",
        marginRight: "auto",
        display: "inline-flex",
        background: `linear-gradient(40deg, rgba(113, 99, 255, 1.0) 10%, rgba(78, 40, 253, 1.0) 80%, rgba(78, 62, 253, 1.0) 100% )`,
        boxShadow: `0 7px 24px -4px rgba(47,0,244,0.24)`,
        width: "100%",
        maxWidth: "170px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius:"100px",
        padding:"12px 16px",
        border: "none",
        color: "white",
        transition: "0.3s"
    },
    hover: {
        filter: "brightness(1.18)"
    },
    focus: {
        transform: "translateY(2px)",
        filter: "brightness(0.95)",
        boxShadow: `0 5px 12px -5px rgba(47,0,244,0.31)`,
    },
    iconSize: {
        width: "16px",
        height: "16px"
    },
    loading: {
        color: "white"
    }
}));

interface BaseButtonProps extends Pick<ButtonBaseProps, Exclude<keyof ButtonBaseProps, "classes">> {
    isLoading?: boolean,
    icon?: any,
    to?: string,
    component?: "span" | "button"
}

function BaseButton (
    {
        component,
        onClick,
        children, type="button",
        disabled,
        isLoading,
        style,
        key,
        icon,
    } : BaseButtonProps) {

    const [pressed, setPressed] = useState(false);
    const [hover, setHover] = useState(false);

    const classes = useStyles();
    const theme = useTheme();

    return <ButtonBase
        component={component}
        key={key}
        disableRipple
        disableTouchRipple
        type={type}
        style={style}
        disabled={disabled  || isLoading}
        className={classNames(
            {[classes.primary] : true},
            {[classes.hover] : hover && !pressed},
            {[classes.focus] : pressed || isLoading},
        )}
        onFocus={() => setHover(true)}
        onBlur={() => setHover(false)}
        onMouseUp={() => setPressed(false)}
        onMouseDown={() => setPressed(true)}
        onClick={onClick ?? null}
        onMouseLeave={() => setPressed(false)}
        onTouchStart={() => setPressed(true)}
        onTouchEnd={() => setPressed(false)}
        onTouchCancel={() => setPressed(false)}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
    >
        <div style={{ opacity:  (isLoading) ? "0.0" : "1.0", transform: `translateY(${(isLoading) ? "8px" : "0"})`, display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s", transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)" }}>
            {
                icon &&
                <span style={{ marginRight: "12px", marginTop: "2px" }}> {icon}</span>
            }
            <Text selectable={false} style={{ color: theme.palette.background.paper, opacity:  (disabled) ? "0.5" : "1.0", wordBreak: "unset" }} bold variant={"body2"}>{children}</Text>
        </div>

        <div style={{ position: "absolute", width: "18px", height: "18px", opacity: isLoading ? 1 : 0,
            transform: `translateY(${(isLoading) ? "0px" : "-8px"})`,  transition: "0.4s", transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)" }}>
            <CircularProgress classes={{ colorPrimary: classes.loading }} size={18} thickness={8}/>
        </div>
    </ButtonBase>
}

function PrimaryButton (props: BaseButtonProps) {

    const base = <BaseButton {...props}/>;

    return ( props.to ?
            (props.to.includes("http") ?
                    <a href={props.to} rel="noopener noreferrer" target={"_blank"}>
                        {base}
                    </a>
                    :
                    <Link to={props.to}>
                        {base}
                    </Link>
            )
            :
            base
    )
}

export default PrimaryButton;