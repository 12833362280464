import React, { Dispatch, useEffect, useState } from "react";
import Text from "../data-display/text";
import useTheme from "@material-ui/core/styles/useTheme";
import { auth } from "../../utils/firebase.utils";
import FormErrorText from "../data-display/form-error-text";
import Form from "../forms/form-wrapper";
import FormInput from "../forms/form-input";
import DialogWrapper from "../dialogs/dialog-wrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import { UserActionTypes } from "../../redux/types/user-type";
import DialogBottomButton from "../dialogs/dialog-bottom-button";
import { users } from "../../utils/firebase.utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { updateUser } from "../../redux/actions/user-actions";
const NameVerification = ({ children, style, }: { children: React.ReactNode; style?: React.CSSProperties; }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();
  const { userData, isVerified, isAuthenticated } = useSelector((state: RootState) => state.userData);
  const [open, setOpen] = useState(false);
  const saveName = async (values: Record<string, string>) => {
    return new Promise((resolve, reject) => {
      const fullname = values.firstName.trim() + values.lastName.trim();
      if (fullname?.length <= 25) {
        users
          .doc(userData.uid)
          .set({ firstName: values.firstName.trim(), lastName: values.lastName.trim() }, { merge: true })
          .then(() => {
            userData.firstName = values.firstName.trim();
            userData.lastName = values.lastName.trim();
            dispatch(updateUser(userData));
          })
          .catch(() => {
            reject("Something went wrong on our side!");
          });
      }
      else if (fullname?.length > 25) {
        reject("Full name must be less than or equal to 25 characters");
      }
    });
  };

  return (
    <div style={style}>
      {isAuthenticated && !userData?.firstName && !userData?.lastName ? (
        <DialogWrapper onClose={() =>auth.signOut()} root={true} onReturn={null} open={isAuthenticated && !userData?.firstName && !userData?.lastName} title={null}>
          <div style={{ height: "400px" }}>
            <Form onSubmit={saveName} initialValues={{ firstName: userData?.firstName.trim(), lastName: userData?.lastName.trim() }}>
              <FormInput label={"First Name"} placeholder={"First Name"} name={"firstName"} type={"text"} required />
              <FormInput label={"Last Name"} placeholder={"Last Name"} name={"lastName"} type={"text"} required />
              <FormErrorText />
              <DialogBottomButton showBackgroundFade={true}>Complete</DialogBottomButton>
            </Form>
          </div>
        </DialogWrapper>
      ) : (
        children
      )}
    </div>
  );
};

export default NameVerification;
