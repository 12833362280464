import React, { useEffect, useRef, useState } from 'react';
import FormErrorText from "../../data-display/form-error-text";
import Form from "../../forms/form-wrapper";
import FormInput from "../../forms/form-input";
import { useDispatch, useSelector } from 'react-redux';
import firebase, { auth, tasks, privateUsers, users } from '../../../utils/firebase.utils';
import Text from '../../data-display/text';
import { RootState } from "../../../redux/index.reducers";
import { Dispatch } from "redux";
import { UserActionTypes } from "../../../redux/types/user-type";
import { updateUser } from "../../../redux/actions/user-actions";
import DialogBottomButton from "../../dialogs/dialog-bottom-button";
import icon from '../img/icon.png'
import AttachmentHandler from '../../attachment-handler/attachment-handler';
import { Grid } from '@material-ui/core';
import BirthDateForm from './birth-date-form';
import DialogWrapper from '../../dialogs/dialog-wrapper';
import { Avatar } from '@material-ui/core';


const PhoneNumberForm = (props) => {
    const [verificationSection, setSection] = useState(false);
    const [complete, setComplete] = useState(false);
    const [number, setNumber] = useState<string>("");
    const inputNumber = useRef(null);
    const next = props.next;
    const [openDialog, setOpenDialog] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const { userData } = useSelector((state: RootState) => state.userData);
    const badges = useState(userData.badges);
    const updateBadges = [
        ...userData.badges,
         {
            date:null,
            name: 'phone',
            status: 'verified',
            verificationId: null
        }
    ];

  
    const handleOpen = (type) => {
        if (userData.birthDate == null) {
            setOpen(true);
            setOpenDialog('birthDate');
        } else props.onClose();
    };

    const handleClose = () => {
        setOpenDialog('');
        setOpen(false)
        props.onClose();
    };

    const dispatch = useDispatch<Dispatch<UserActionTypes>>();


    useEffect(() => {
        window['phoneRecaptchaVerifier'] = new firebase.auth.RecaptchaVerifier(inputNumber.current,
            {
                size: "invisible"
            });

    }, [])
    const submitPhoneNumberAuth = (values: Record<string, string | boolean>) => {
        return new Promise(async (resolve, reject) => {
            if (userData.phoneNumber !== null) {
                let hasPhoneProvider = false;
                auth.currentUser.providerData.map((provider) => {
                    if (provider.providerId === "phone") {
                        hasPhoneProvider = true;
                    }
                })
                if (hasPhoneProvider)
                    await auth.currentUser.unlink("phone").then(() => { }).catch((error) => {
                        reject(error.message)
                    });
            }
            if (values.mobileNumber) {
                setNumber(values.mobileNumber.toString())
            }
            else
                return reject({ mobileNumber: "Your Phone Number is invalid." });

            let phoneNumber = values.mobileNumber.toString();
            phoneNumber = phoneNumber.replace(/\s/g, '');
            phoneNumber = phoneNumber.replace(/-/g, '');
            phoneNumber = phoneNumber.replace(/[{()}]/g, '');
            phoneNumber = "+1" + phoneNumber;

            let appVerifier = window['phoneRecaptchaVerifier'];
            firebase
                .auth()
                .currentUser.linkWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    window['confirmationResult'] = confirmationResult;
                    setSection(true);
                    setComplete(true);
                })
                .catch((error) => {
                    switch (error.code) {
                        case "auth/invalid-phone-number":
                            return reject({ mobileNumber: "Your Phone Number is invalid." });
                        case "auth/provider-already-linked":
                            return reject({ mobileNumber: "Phone number is already in use." });
                        case "auth/phone-number-already-exists":
                            return reject("Phone number is already in use.");
                        default:
                            return reject("Something went wrong on our side.");
                    }
                });
        })
    }

    const submitPhoneNumberAuthCode = (values: Record<string, string | boolean>) => {
        next && props.onContinue()
        return new Promise((resolve, reject) => {
            window['confirmationResult']
                .confirm(values.code)
                .then(() => {
                    privateUsers.doc(userData.uid).set(
                        { phoneNumber: number }, { merge: true })
                        .then(() => {
                            if (props?.isPostTask) {
                                let t = JSON.parse(JSON.stringify(props?.task?.toJson()));
                                t["createAt"] = new Date(t["createAt"]);
                                t["dateToStart"] = new Date(t["dateToStart"]);
                                tasks
                                    .add(t)
                                    .then((doc) => {
                                        userData.phoneNumber = number;
                                        dispatch(updateUser(userData));
                                        props?.saveNewTaskId(doc?.id);
                                        props?.onContinue();
                                    })
                                    .catch((e) => {
                                        console.log(e)
                                    });
                            } else {

                                userData.phoneNumber = number;
                                users
                                    .doc(userData.uid)
                                    .set({ badges: updateBadges }, { merge: true })
                                dispatch(updateUser(userData));
                                handleOpen('birthDate')
                            }
                        })
                        .catch(() => {
                            return reject("Something went wrong on our side.");
                        })
                })
                .catch((error) => {
                    switch (error.code) {
                        case "auth/invalid-verification-code":
                            return reject({ code: "The verification code is invalid." })
                        case "auth/credential-already-in-use":
                            return reject("Phone number is already in use.");
                        default:
                            return reject("Something went wrong on our side.");
                    }
                });
        })
    }

    return (
        <div style={{ height: "100%" }}>
            {!verificationSection ?
                <Form onSubmit={submitPhoneNumberAuth} initialValues={userData.phoneNumber ? { mobileNumber: userData.phoneNumber } : {}} style={{ display: "flex", height: "100%" }}>
                    {props?.isPostTask && <Text variant={"subtitle2"} component={'p'}>Enter your phone number to complete posting your task.</Text>}
                    <Grid container direction={"row"} style={{ marginTop: "30px" }}>
                    <div style={{ height: "30px", width: "35px", marginTop: "30px",marginRight:"8px" }}>
                            <Avatar variant={"rounded"} alt="The image" src={icon} />
                        </div>
                        <Text style={{ marginTop: "40px" }} variant={'body2'}>+1</Text>
                        <div>
                            <FormInput label={"phone number"}  style={{ width: "300px" }} placeholder={"Mobile Number"} name={"mobileNumber"} type={"tel"} required />
                        </div>

                    </Grid>

                    {props?.isPostTask && <Text variant={"subtitle1"} bold component={'p'}>YOUR PHONE NUMBER WON'T BE VISIBLE TO OTHERS.</Text>}
                    <FormErrorText />
                    <DialogBottomButton type='submit' showBackgroundFade={false}>
                        Send Verification Code
                    </DialogBottomButton>
                </Form>
                : null
            }
            {
                verificationSection && complete ?
                    <Form  onSubmit={submitPhoneNumberAuthCode} style={{ display: "flex", height: '100%' }}>
                        <Text variant={"subtitle2"} component={'p'}>We have sent a verification code to your number</Text>
                        <FormInput autoComplete={"off"} placeholder={"Verification Code"} name={"code"} type={"number"} required />
                        <FormErrorText />
                        <DialogBottomButton  type='submit' showBackgroundFade={false}>
                            Verify Code
                        </DialogBottomButton>
                    </Form>
                    : null
            }
            <div ref={inputNumber} />
            {open && <DialogWrapper root title={"Edit Birth Date"} onClose={handleClose} open={openDialog === "birthDate"}>
                <div style={{ height: "500px" }}>
                    <div style={{ height: "2px" }} />
                    <BirthDateForm onClose={handleClose} />
                </div>
            </DialogWrapper>}

        </div>
    );
};

export default PhoneNumberForm;