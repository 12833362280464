import React, {useState} from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import {FiArrowUpRight} from "react-icons/fi";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    primary: {
        opacity: "1",
        cursor: "pointer",
        padding: "8px 0px",
        color: theme.palette.primary.main,
        position: "relative",
        transition: theme.transitions.create([ 'opacity' ], {
            easing: "cubic-bezier(.6,.19,.04,.92)",
            duration: "0.2s",
        }),
        "&:hover": {
            opacity: "0.8"
        }
    },
    secondary: {
        cursor: "pointer",
        padding: "8px 0px",
        color: theme.palette.primary.main,
        position: "relative",
        opacity: "0.7",
        transition: theme.transitions.create([ 'opacity' ], {
            easing: "cubic-bezier(.6,.19,.04,.92)",
            duration: "0.2s",
        }),
        "&:hover": {
            opacity: "1.0"
        }
    },
    disabled: {
        pointerEvents: "none"
    }
}));

const InlineLink  = (
    {children, disableArrow=false, variant="primary", onClick, to, style={}, ariaControls, ariaHasPopup, disabled=false, spanStyle={}}:
    {
        children: React.ReactNode,
        variant?: 'primary' | 'secondary',
        onClick?: () => void,
        to?: string,
        style?: React.CSSProperties,
        ariaControls?: string,
        ariaHasPopup?: boolean | 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog',
        disabled?: boolean,
        spanStyle?: React.CSSProperties,
        disableArrow?: boolean,
    })  => {

    const classes = useStyles();
    const [hover, setHover] = useState(false);

    const switchHover = () => {
        setHover(!hover);
    };

    const classNamesValue = classNames({
        [classes[variant]]: true,
        [classes.disabled]: disabled
    });

    const textStyle = { color: disabled ? "grey" : "inherit", ...spanStyle };

    return to ?
        ((to.includes("http")) ||  (to.includes("mailto")) || (to.includes("tel")) ?
                <a href={to} rel="noopener noreferrer" target={"_blank"}
                   onClick={onClick ?? undefined}
                   className={classNamesValue}
                   style={style} onMouseEnter={switchHover} onMouseLeave={switchHover}>
                    <span style={textStyle}>{ children }</span>
                    {!disableArrow &&
                        <FiArrowUpRight style={{ height: "14px", width: "14px" }}/>
                    }
                </a>
                    :
                    <Link to={to}
                          onClick={onClick ?? undefined}
                          className={classNamesValue}
                          style={style} onMouseEnter={switchHover} onMouseLeave={switchHover}>
                        <span style={textStyle}>{ children }</span>
                    </Link>

        )
        :
        <span className={classNamesValue}
              style={style}
              onMouseEnter={switchHover} onMouseLeave={switchHover}
              onClick={onClick}
              aria-controls={ariaControls}
              aria-haspopup={ariaHasPopup}
        >
            <span style={textStyle}>{ children }</span>
        </span>
}

export default InlineLink;