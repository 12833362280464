
export const GooglePlaces = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAqtUypQObeGz4IGYx56-D3A3HhifQeRmk&libraries=places&language=en';
export const GoogleMaps = 'AIzaSyBjFEh8BnuyHRNju8Z7gmnO6PuZJ0x-41w';

let ALGOLIA_APP_ID: string;
let ALGOLIA_API_KEY: string;
let STRIPE_KEY: string;
let baseUrl: string;
let endPoint: string;
let storageFB: string;


if (process.env.GATSBY_APP_ENV === 'production') {
    ALGOLIA_APP_ID = "RU30XYALZ8";
    ALGOLIA_API_KEY = "1205d7f2f76daa3020ab7e2a05b6d6f4";
    STRIPE_KEY = "pk_live_8ZBaouvp0k3nv0NjKlRvJWNp001Qbjdiuu";
    baseUrl = "https://us-central1-sydework-api.cloudfunctions.net";
    endPoint = "https://ik.imagekit.io/wffo0jsx3/syde-prod";
    storageFB = "https://firebasestorage.googleapis.com/v0/b/sydework-api.appspot.com";
} else {
    ALGOLIA_APP_ID = "M74YSN7MXQ";
    ALGOLIA_API_KEY = "f44dd79ac582f90c79fc265809e8866f";
    STRIPE_KEY = "pk_test_eVqJChIYUuO5Uo0joF9ka5fB00EJpSo6UV";
    baseUrl = "https://us-central1-sydework-dev.cloudfunctions.net";
    endPoint ="https://ik.imagekit.io/wffo0jsx3/syde-dev";
    storageFB = "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com";
}

export { ALGOLIA_APP_ID, ALGOLIA_API_KEY, STRIPE_KEY, baseUrl,endPoint,storageFB };
