import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox, {CheckboxProps} from '@material-ui/core/Checkbox';
import {FormControlLabel ,FormHelperText} from '@material-ui/core';
import Text from '../data-display/text';

const useStyles = makeStyles({
     root: {
          '&:hover': {
               backgroundColor: 'transparent',
          },
          padding: 0,
          paddingRight: 9,
          margin: 0,
     },
     icon: {
          borderRadius: 50,
          width: 15,
          height: 15,
          boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
          backgroundColor: '#f5f8fa',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
          '$root.Mui-focusVisible &': {
               outline: '2px auto rgba(19,124,189,.6)',
               outlineOffset: 2,
          },
          'input:hover ~ &': {
               backgroundColor: '#ebf1f5',
          },
          'input:disabled ~ &': {
               boxShadow: 'none',
               background: 'rgba(206,217,224,.5)',
          },
     },
     checkedIcon: {
          backgroundColor: 'rgba(78, 62, 253, 1.0)',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
          '&:before': {
               display: 'block',
               width: 15,
               height: 15,
               backgroundImage:
                  'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
                  ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
                  '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
               content: '""',
          },
          'input:hover ~ &': {
               backgroundColor: 'rgba(78, 62, 253, 0.5)',
          },
     },
     labelRoot: {
          display: 'flex',
          margin:0,
          alignItems:'flex-start'
     },
     errorStyle: {
          borderRadius: 50,
          width: 15,
          height: 15,
          backgroundColor: '#f5f8fa',
          backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
          '$root.Mui-focusVisible &': {
               outline: '2px auto rgba(19,124,189,.6)',
               outlineOffset: 2,
          },
          'input:hover ~ &': {
               backgroundColor: '#ebf1f5',
          },
          'input:disabled ~ &': {
               boxShadow: 'none',
               background: 'rgba(206,217,224,.5)',
          },
          border: '2px solid rgba(235, 80, 60, 0.5)',
     },
});

interface CustomProps {
     text: string;
     name: string;
     error?: string | undefined,
     errorText?:string,
}

function FormCheckbox(props: CheckboxProps & CustomProps) {
     const classes = useStyles();
     return (
          <div>
        <FormControlLabel
           control={
                <Checkbox
                   disableRipple
                   color="default"
                   checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
                   icon={<span className={(props.error && props.error !== '')
                      ? classes.errorStyle
                      : classes.icon}/>}
                   inputProps={{'aria-label': 'decorative checkbox'}}
                   {...props}
                   className={classes.root}
                />
           }
           
           label={<Text variant={'caption'} medium component={'p'}>{props.text}</Text>}
           labelPlacement="end"
           name={props.name}
           className={classes.labelRoot}
        />
         {props?.error !== '' && props?.error !== ''&& <FormHelperText style={{color:"red" ,padding:"10px 0px", paddingLeft:"15px"}}>{props?.errorText}</FormHelperText>}
</div>
     );
};

export default FormCheckbox;