import React, { Dispatch, useEffect, useState } from "react";
import Text from "../../components/data-display/text";
import useTheme from "@material-ui/core/styles/useTheme";
import { auth } from "../../utils/firebase.utils";
import InlineLink from "../../components/data-display/InlineLink";
import DialogWrapper from "../../components/dialogs/dialog-wrapper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/index.reducers";
import { FiMail } from "react-icons/fi";
import { UserActionTypes } from "../../redux/types/user-type";
import DialogBottomButton from "../../components/dialogs/dialog-bottom-button";
import { setUser } from "../../redux/actions/user-actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { baseUrl } from "../../utils/constant.urls";
import axios from "axios";
import firebase from "firebase";

const VerificationWrapper = ({ children, style, open, closeDialog }: { children: React.ReactNode; style?: React.CSSProperties; open: boolean; closeDialog: () => void }) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();
  const { userData, isVerified } = useSelector((state: RootState) => state.userData);

  const [count, setCount] = useState(60);
  const [disabled, setDisabled] = useState(false);

  const checkVerificationStatus = () => {
    let user = auth?.currentUser;

    user.reload().then(() => {
      user = auth?.currentUser;
      dispatch(setUser({ user: user, userData: userData }));
    });
  };
  
  useEffect(()=>{
    firebase.analytics().logEvent("sign_up");
  },[])

  const sendEmail = () => {
    if (!disabled) {
      let user = auth?.currentUser;
      axios
      .post(
          `${baseUrl}/sendVerificationEmail`,
          {
              email: user.email
          }
      )
      .then(() => {
          console.log('success')
      })
      .catch(async (err) => {
          console.log(err.code);
      });
      firebase.analytics().logEvent("sign_up");
      setDisabled(true);
    }
  };

  useEffect(() => {
    let interval;
    let counter = 60;
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, 60000);
      interval = setInterval(() => {
        if (counter <= 1) {
          clearInterval(interval);
          setCount(60);
        } else {
          counter -= 1;
          setCount((c) => c - 1);
        }
      }, 1000);
    }
  }, [disabled]);

  return (
    <div style={style}>
      {!isVerified ? (
        <DialogWrapper onClose={closeDialog} root={true} onReturn={null} open={open} title={null}>
          <div style={{ height: xsDown ? "calc(100vh - 100px)" : "500px" }}>
            <div style={{ height: "2px" }} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img loading={"lazy"} src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fverify.png?alt=media&token=2999bc36-a7b3-45d9-a226-bac997283e14'} width={"150px"} style={{ marginTop: "32px" }} alt={"Verify your email"} />
              <Text variant={"body1"} bold style={{ marginTop: "8px", textAlign: "center", lineHeight: "1" }}>
                We sent you a <br />
                Verification Email!
              </Text>
              <div style={{ padding: "0 12px", marginTop: "12px" }}>
                <Text variant={"body2"} regular style={{ marginTop: "6px", color: "#707070" }}>
                  Thanks for signing up! You should see a verification link in your email (it might be in your spam folder). Verify your email start using Sydetasker today.
                </Text>
              </div>
              <div
                style={{
                  width: "fit-content",
                  background: "white",
                  padding: "8px 14px",
                  boxShadow: "0px 4.0px 24.0px -8.0px rgba(47, 0, 244, 0.15)",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "24px 0"
                }}
              >
                <FiMail size={18} color={theme.palette.primary.main} />
                <Text variant={"body2"} style={{ marginLeft: "10px" }}>
                  {userData?.email}
                </Text>
              </div>
              <Text variant={"body2"} bold style={{ color: "#707070", marginTop: "10px" }}>
                Didn't Receive?{" "}
                <InlineLink onClick={sendEmail} style={{ transition: "0.3s", color: count !== 60 ? "#707070" : theme.palette.primary.main, pointerEvents: count !== 60 ? "none" : "unset" }} variant={"secondary"}>
                  Resend
                </InlineLink>
              </Text>
              <div style={{ transition: "0.3s", opacity: count !== 60 && count > 1 ? 1 : 0 }}>
                <Text variant={"caption"} medium style={{ color: "#707070", marginTop: "0px" }}>
                  Retry in {count}s
                </Text>
              </div>
            </div>
            <DialogBottomButton onClick={checkVerificationStatus}>Continue</DialogBottomButton>
          </div>
        </DialogWrapper>
      ) : (
        children
      )}
    </div>
  );
};

export default VerificationWrapper;
